import { IoMdNotificationsOutline, IoMdArrowDropdown, IoIosLogOut } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
let name = localStorage.getItem("name");
export default function UserId(){
    const naviget = useNavigate();
    function logoutSubmit(){
        
        localStorage.setItem("loginA", false);
        localStorage.setItem("loginStatus", "Sesión Cerrada Correctamente");
       
        naviget("/");
    }
return (
    <div className="w-32 h-16 flex items-center space-x-5 float-right sticky rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
              
            <div className="flex items-center">
                    
                    <button className='flex' onClick={logoutSubmit}>
                    
                        <IoIosLogOut style={{color: 'grey', fontSize: '2.5rem'}}></IoIosLogOut></button>
            </div>
    </div>
);


}