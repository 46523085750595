import React, { useState } from "react";
import Modal from "react-modal";
import UserId from "../components/logout";
import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuA";
import axios from "axios";
import { useEffect } from "react";
import config from "../config/env";

export default function Add() {
  const [modalOpenUser, setModalOpen] = useState(false); // Estado del primer modal
  const [modalDocenteOpen, setModalDocenteOpen] = useState(false); // Estado del modal para docente
  const [modalAsesorOpen, setModalAsesorOpen] = useState(false); // Estado del modal para asesor
  const [modalVincularOpen, setModalVincularOpen] = useState(false); // Estado del modal para vincular docente-alumno

  // ... (código existente)

  const [asesores, setAsesores] = useState([]);
  const asesorStatus = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/allAsesores.php`
    );
    setAsesores(response.data);
  };

  useEffect(() => {
    asesorStatus();
  }, []);

  const [users, setUsers] = useState([]);

  const usersStatus = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/AllUsers.php`
    );
    setUsers(response.data);
  };

  useEffect(() => {
    usersStatus();
  }, []);

  const [docente, setDocente] = useState([]);

  const userDocente = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/all_docentes.php`
    );
    setDocente(response.data);
  };

  useEffect(() => {
    userDocente();
  }, []);

  const openModalUser = () => {
    setModalOpen(true);
  };

  const closeModalUser = () => {
    setModalOpen(false);
  };

  // ... (código existente)

  const openModalDocente = () => {
    setModalDocenteOpen(true);
  };

  const closeModalDocente = () => {
    setModalDocenteOpen(false);
  };

  // ... (código existente)

  const openModalAsesor = () => {
    setModalAsesorOpen(true);
  };

  const closeModalAsesor = () => {
    setModalAsesorOpen(false);
  };

  // ... (código existente)

  const openModalVincular = () => {
    setModalVincularOpen(true);
  };

  const closeModalVincular = () => {
    setModalVincularOpen(false);
  };

  // ... (código existente)

  return (
    <div className="container px-0 items-center">
      <div className="w-full flex space-x-[75%] items-center">
        <img src={TECN} alt="logotec" className="w-40" />
        <UserId />
      </div>
      <Menu />
      <div className="w-5/6 h-auto grid mx-auto">
        <div className="w-full p-5 h-auto flex flex-cols-2 rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
          <div className="gap-2 m-4">
            {/* Botón y modal para usuario */}
            <button
              onClick={openModalUser}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Estudiante
            </button>
            <Modal isOpen={modalOpenUser} onRequestClose={closeModalDocente}>
              {/* Contenido del Modal para User */}
              <button
                onClick={closeModalUser}
                className="text-red-600 font-extraboldbold text-4xl float-right"
              >
                X
              </button>
              <div className="gap-2">
                <p className="font-bold text-blue-950">Agregar Usuario</p>
                <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                <form
                  method="POST"
                  action={`${config.BASE_URL}/API_DualSystem/registrar_user.php`}
                >
                  <label className="font-bold text-blue-950" htmlFor="">
                    Correo
                  </label>
                  <input
                    placeholder="Correo"
                    name="correo"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Password
                  </label>
                  <input
                    placeholder="Contraseña"
                    name="password"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Nombre
                  </label>
                  <input
                    placeholder="nombre"
                    name="name"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Paterno
                  </label>
                  <input
                    placeholder="Apellido Paterno"
                    name="last_f"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Materno
                  </label>
                  <input
                    placeholder="Apellido Materno"
                    name="last_m"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    No. Control
                  </label>
                  <input
                    placeholder="Numero Control"
                    name="N_control"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Curp
                  </label>
                  <input
                    placeholder="Curp"
                    name="curp"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Telefono
                  </label>
                  <input
                    placeholder="Telefono"
                    name="phone"
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Fecha Nacimiento
                  </label>
                  <input
                    name="fecha"
                    type="date"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Dirección
                  </label>
                  <input
                    placeholder="Direccion"
                    name="adress"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <select
                    name="semestre"
                    id=""
                    className="mb-5 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">SEMESTRE</option>
                    <option value="1">Primer</option>
                    <option value="2">Segundo</option>
                    <option value="3">Tercero</option>
                    <option value="4">Cuarto</option>
                    <option value="5">Quinto</option>
                    <option value="6">Sexto</option>
                    <option value="7">Septimo</option>
                    <option value="8">Octavo</option>
                    <option value="9">Noveno</option>
                    <option value="10">Decimo</option>
                    <option value="11">Onceavo</option>
                    <option value="12">Doceavo</option>
                  </select>

                  <select
                    name="carrera"
                    id=""
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">Carrera</option>
                    <option value="111">Ing. Sistemas Computacionales</option>
                    <option value="222">Ing. Industrial</option>
                    <option value="333">Ing. Gestión Empresarial</option>
                    <option value="444">Ing. Nanotecnologia</option>
                    <option value="555">Ing. Mecatronica</option>
                    <option value="666">Ing. Electromecanica</option>
                    <option value="777">Ing. Electronica</option>
                    <option value="888">Ing. Geociencias</option>
                    <option value="999">Lic. Contabilidad</option>
                  </select>

                  <button
                    type="submit"
                    id="env"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Registrar
                  </button>
                </form>
              </div>
              {/* ... (código existente) */}
            </Modal>
          </div>

          {/* Agrega botones y modales adicionales */}
          <div className="gap-2 m-4">
            {/* Botón y modal para docente */}
            <button
              onClick={openModalDocente}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Docente
            </button>
            <Modal isOpen={modalDocenteOpen} onRequestClose={closeModalDocente}>
              {/* Contenido del Modal para docente */}
              <button
                onClick={closeModalDocente}
                className="text-red-600 font-extraboldbold text-4xl float-right"
              >
                X
              </button>
              <div className="gap-2 ml-6">
                <p className="font-bold text-blue-950">Agregar Docente</p>
                <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                <form
                  method="POST"
                  action={`${config.BASE_URL}/API_DualSystem/registrar_docente.php`}
                >
                  <label className="font-bold text-blue-950" htmlFor="">
                    Correo
                  </label>
                  <input
                    placeholder="Correo"
                    name="correo"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Password
                  </label>
                  <input
                    placeholder="Contraseña"
                    name="password"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Nombre
                  </label>
                  <input
                    placeholder="nombre"
                    name="name"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Paterno
                  </label>
                  <input
                    placeholder="Apellido Paterno"
                    name="last_f"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Materno
                  </label>
                  <input
                    placeholder="Apellido Materno"
                    name="last_m"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    No. Control
                  </label>
                  <input
                    placeholder="Numero Control"
                    name="N_control"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Telefono
                  </label>
                  <input
                    placeholder="Telefono"
                    name="phone"
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <select
                    name="carrera"
                    id=""
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">carrera</option>
                    <option value="111">Ing. Sistemas Computacionales</option>
                    <option value="222">Ing. Industrial</option>
                    <option value="333">Ing. Gestión Empresarial</option>
                    <option value="444">Ing. Nanotecnologia</option>
                    <option value="555">Ing. Mecatronica</option>
                    <option value="666">Ing. Electromecanica</option>
                    <option value="777">Ing. Electronica</option>
                    <option value="888">Ing. Geociencias</option>
                    <option value="999">Lic. Contabilidad</option>
                  </select>

                  <button
                    type="submit"
                    id="env"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Registrar
                  </button>
                </form>
              </div>
              {/* ... (código existente) */}
            </Modal>
          </div>

          <div className="gap-2 m-4">
            {/* Botón y modal para asesor */}
            <button
              onClick={openModalAsesor}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Asesor
            </button>
            <Modal isOpen={modalAsesorOpen} onRequestClose={closeModalAsesor}>
              {/* Contenido del Modal para asesor */}
              <button
                onClick={closeModalAsesor}
                className="text-red-600 font-extraboldbold text-4xl float-right"
              >
                X
              </button>
              <div className="gap-2 ml-6">
                <p className="font-bold text-blue-950">Agregar GG??Asesor</p>
                <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                <form
                  method="POST"
                  action={`${config.BASE_URL}/API_DualSystem/registro_AGGsesor.php`}
                >
                  <label className="font-bold text-blue-950" htmlFor="">
                    Correo
                  </label>
                  <input
                    placeholder="Correo"
                    name="correo"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Password
                  </label>
                  <input
                    placeholder="Contraseña"
                    name="password"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Nombre
                  </label>
                  <input
                    placeholder="nombre"
                    name="name"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Paterno
                  </label>
                  <input
                    placeholder="Apellido Paterno"
                    name="last_f"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Apellido Materno
                  </label>
                  <input
                    placeholder="Apellido Materno"
                    name="last_m"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Empresa
                  </label>
                  <input
                    placeholder="Numero Control"
                    name="empresa"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Telefono
                  </label>
                  <input
                    placeholder="Telefono"
                    name="phone"
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Area
                  </label>
                  <input
                    placeholder="Telefono"
                    name="area"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <label className="font-bold text-blue-950" htmlFor="">
                    Ubicación
                  </label>
                  <input
                    placeholder="Telefono"
                    name="adres"
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />

                  <select
                    name="carrera"
                    id=""
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">carrera</option>
                    <option value="111">Ing. Sistemas Computacionales</option>
                    <option value="222">Ing. Industrial</option>
                    <option value="333">Ing. Gestión Empresarial</option>
                    <option value="444">Ing. Nanotecnologia</option>
                    <option value="555">Ing. Mecatronica</option>
                    <option value="666">Ing. Electromecanica</option>
                    <option value="777">Ing. Electronica</option>
                    <option value="888">Ing. Geociencias</option>
                    <option value="999">Lic. Contabilidad</option>
                  </select>

                  <button
                    type="submit"
                    id="env"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Registrar
                  </button>
                </form>
              </div>
              {/* ... (código existente) */}
            </Modal>
          </div>

          <div className="gap-2 m-4">
            {/* Botón y modal para vincular docente-alumno */}
            <button
              onClick={openModalVincular}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Vincular Estudiante
            </button>
            <Modal
              isOpen={modalVincularOpen}
              onRequestClose={closeModalVincular}
            >
              {/* Contenido del Modal para vincular docente-alumno */}
              <button
                onClick={closeModalVincular}
                className="text-red-600 font-extraboldbold text-4xl float-right"
              >
                X
              </button>
              <div className="gap-2 ml-6">
                {/**------------------------------------------------------------------------------------------------------------- */}
                <p className="font-bold text-blue-950">
                  Vincular Docente-Alumno
                </p>
                <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />

                <form
                  method="POST"
                  action={`${config.BASE_URL}/API_DualSystem/asignar_docente.php`}
                >
                  <select
                    name="asesor"
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">Selecciona un docente</option>
                    {asesores.map((user) => (
                      <option
                        key={user.id_asesor}
                        value={user.id_asesor}
                      >{`${user.name_a} ${user.apep_a} ${user.apem_a}`}</option>
                    ))}
                  </select>

                  <select
                    name="docente"
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">Selecciona un docente</option>
                    {docente.map((user) => (
                      <option
                        key={user.ID_docente}
                        value={user.ID_docente}
                      >{`${user.name_d} ${user.apep_d} ${user.apem_d}`}</option>
                    ))}
                  </select>

                  <select
                    name="alumno"
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">Selecciona un Alumno</option>
                    {users.map((user) => (
                      <option
                        key={user.ID_student}
                        value={user.ID_student}
                      >{`${user.name} ${user.last_f} ${user.last_m}`}</option>
                    ))}
                  </select>

                  <select
                    name="semestre"
                    className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="#">Selecciona un Alumno</option>

                    <option value="1">Primero</option>
                    <option value="2">Segundo</option>
                    <option value="3">Tercer</option>
                    <option value="4">Cuarto</option>
                    <option value="5">Quinto</option>
                    <option value="6">Sexto</option>
                    <option value="7">Septimo</option>
                    <option value="8">Octavo</option>
                    <option value="9">Noveno</option>
                    <option value="10">Decimo</option>
                    <option value="11">Onceavo</option>
                    <option value="12">Doceavo</option>
                  </select>

                  <button
                    type="submit"
                    id="env"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Registrar
                  </button>
                </form>
              </div>

              {/* ... (código existente) */}
            </Modal>
          </div>

          {/* ... (código existente) */}
        </div>
      </div>
    </div>
  );
}
