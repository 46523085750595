import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { IoIosTimer } from "react-icons/io";
import config from "../config/env";

export default function InfoC() {
  const [burn, setBurn] = useState([]);
  const [burndos, setBurndos] = useState([]);

  const burnStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/burn.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((db) => {
        setBurn(db.data);
      });
  };
  useEffect(() => {
    burnStatus();
  }, []);

  const burndosStatus = async () => {
    await axios
      .get(
        `${
          config.BASE_URL
        }/API_DualSystem/burndos.php?id=${localStorage.getItem("id")}`
      )
      .then((db) => {
        setBurndos(db.data);
      });
  };
  useEffect(() => {
    burndosStatus();
  }, []);

  const [fecha, setFecha] = useState(new Date());

  useEffect(() => {
    // Actualiza la fecha cada segundo
    const intervalID = setInterval(() => {
      setFecha(new Date());
    }, 1000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  // Formatea la fecha en un formato legible por humanos
  const fechaFormateada = fecha.toLocaleDateString();

  return (
    <div className="flex w-full mt-5 ml-32">
      <div className="mr-6">
        {burn.length === 0 ? (
          <div className="grid place-items-center">
            <Link to={"mbi"}>
              <div className="w-96 p-5 h-48 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <p className="title text-base font-bold text-left text-blue-900 m-1">
                  Completar Maslach Burnout Inventory (MBI)
                </p>
                <p className="text-xs text-gray-800 text-left">
                  Este test pretende medir la frecuencia y la intensidad con la
                  que se sufre el Burnout.
                </p>
                <div className="flex mt-10 items-center">
                  <p className="text-sm text-gray-700 font-semibold">
                    {fechaFormateada}
                  </p>
                  <p className="ml-40 text-orange-600 font-semibold text-sm">
                    10 minutos
                  </p>
                  <IoIosTimer color="orange" size={"2rem"} />
                </div>
                <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />
              </div>
            </Link>
          </div>
        ) : (
          burn.map(function mbiC(mbicheck) {
            if (mbicheck.burnout == 0) {
              return (
                <div className="grid place-items-center">
                  <div className="w-3/4 p-5 h-80 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <p className="title text-base font-bold text-center text-blue-900 m-3">
                      COMPLETADO: MBI
                    </p>
                    <p className="title text-base font-semibold text-gray-800">
                      Resultados:
                    </p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Cansancio emocional</th>
                          <th>Despersonalización</th>
                          <th>Realización personal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center text-orange-600">
                          <td>
                            {mbicheck.cansancio_e <= 18
                              ? "Bajo"
                              : mbicheck.cansancio_e <= 26
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.cansancio_e}
                            </p>
                          </td>
                          <td>
                            {mbicheck.despersonalizacion <= 5
                              ? "Bajo"
                              : mbicheck.despersonalizacion <= 9
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.despersonalizacion}
                            </p>
                          </td>
                          <td>
                            {mbicheck.r_personal <= 33
                              ? "Bajo"
                              : mbicheck.r_personal <= 39
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.r_personal}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="m-3 text-center font-bold text-green-500 text-2xl">
                      ¡Felicidades, sigue así! :D
                    </p>
                    <p className="text-center font-light text-base text-gray-950">
                      No cuentas con indicios del síndrome de Burnout
                    </p>
                  </div>
                </div>
              );
            } else if (mbicheck.burnout == 1) {
              return (
                <div className="grid place-items-center">
                  <div className="w-3/4 p-5 h-80 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <p className="title text-base font-bold text-center text-blue-900 m-3">
                      COMPLETADO: MBI
                    </p>
                    <p className="title text-base font-semibold text-gray-800">
                      Resultados:
                    </p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Cansancio emocional</th>
                          <th>Despersonalización</th>
                          <th>Realización personal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center text-orange-600">
                          <td>
                            {mbicheck.cansancio_e <= 18
                              ? "Bajo"
                              : mbicheck.cansancio_e <= 26
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.cansancio_e}
                            </p>
                          </td>
                          <td>
                            {mbicheck.despersonalizacion <= 5
                              ? "Bajo"
                              : mbicheck.despersonalizacion <= 9
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.despersonalizacion}
                            </p>
                          </td>
                          <td>
                            {mbicheck.r_personal <= 33
                              ? "Bajo"
                              : mbicheck.r_personal <= 39
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.r_personal}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="m-3 text-center font-bold text-green-500 text-2xl">
                      Diagnostico: Indicios de síndrome de Burnout.
                    </p>
                    <p className="text-center font-light text-base text-gray-950">
                      ¡Necesitas un descanso!
                    </p>
                  </div>
                </div>
              );
            }
          })
        )}
      </div>

      {/**------------------------------------------------------------------------------ */}

      <div>
        {burndos.length === 0 ? (
          <div className="grid place-items-center">
            <Link to={"riesgops"}>
              <div className="w-96 p-5 h-48 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <p className="title text-base font-bold text-left text-blue-900 m-1">
                  Riesgo Psicosocial
                </p>
                <p className="text-xs text-gray-800 text-left">
                  Este test pretende Identificar los factores de Riesgo
                  Psicolosocial.
                </p>
                <div className="flex mt-10 items-center">
                  <p className="text-sm text-gray-700 font-semibold">
                    {fechaFormateada}
                  </p>
                  <p className="ml-40 text-orange-600 font-semibold text-sm">
                    10 minutos
                  </p>
                  <IoIosTimer color="orange" size={"2rem"} />
                </div>
                <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-amber-400" />
              </div>
            </Link>
          </div>
        ) : (
          burndos.map((mbicheckdos) => (
            <div className="grid place-items-center" key={mbicheckdos}>
              <div className="w-3/4 p-5 h-80 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <p className="title text-base font-bold text-center text-blue-900 m-3">
                  COMPLETADO: MBI
                </p>
                <p className="title text-base font-semibold text-gray-800">
                  Resultados:
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Calificación</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center text-orange-600">
                      <td>
                        {mbicheckdos.burnout} <br />{" "}
                        <p className="text-blue-500">
                          {mbicheckdos.status == 1
                            ? "Nulo"
                            : mbicheckdos.status == 2
                            ? "Bajo"
                            : mbicheckdos.status == 3
                            ? "Medio"
                            : mbicheckdos.status == 4
                            ? "Alto"
                            : mbicheckdos.status == 5
                            ? "Muy Alto"
                            : "Error"}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="m-3 text-center font-bold text-green-500 text-xs">
                  {mbicheckdos.status == 1
                    ? "El riesgo resulta despreciable por lo que no se requiere medidas adicionales."
                    : mbicheckdos.status == 2
                    ? "Es necesario una mayor difusión de la política de prevención de riesgos psicosociales y programas para: la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral."
                    : mbicheckdos.status == 3
                    ? "Se requiere revisar la política de prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, así como reforzar su aplicación y difusión, mediante un Programa de intervención."
                    : mbicheckdos.status == 4
                    ? "Se requiere realizar un análisis de cada categoría y dominio, de manera que se puedan determinar las acciones de intervención apropiadas a través de un Programa de intervención, que podrá incluir una evaluación especiftca1 y doberá incluir una campaña do sensibilización, revisar la política do prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psicosocial, la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, así como reforzar su a licación difusión."
                    : mbicheckdos.status == 5
                    ? "Se requiere realizar el análisis de cada categorla y dominio para establecer las acciones de intervención apropiadas, mediante un Programa de intervención que deberá incluir evaluaciones especificas 1, y contemplar campaías de sensiblllzación, revisar la polltlca de prevención de riesgos psicosociales y programas para la prevención de los factores de riesgo psioosocial. la promoción de un entorno organizacional favorable y la prevención de la violencia laboral, asi como reforzar su aplicación y difusión."
                    : "Error"}
                </p>
                <p className="m-3 text-center font-bold text-green-950 text-xs"></p>
              </div>
            </div>
          ))
        )}
      </div>

      {/**--------------------------------------------------------------------------------- */}
    </div>
  );
}
