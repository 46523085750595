import axios from "axios";
import { useEffect, useState } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import config from "../config/env";

export default function CvC(){
    const [file, setFile]= useState([]);

    const filesReciv = async()=>{
        await axios.get(`${config.BASE_URL}/API_DualSystem/cv.php?id=${localStorage.getItem("id")}`)
        .then(db => {
            setFile(db.data);
        })
    }
    useEffect(()=>{
        filesReciv();
    }, [])


    
   
        
           
    
    
   
  

   return (
    <div>
    {file.length === 0 ? (
                <Link to={'cv'}>
                <div>
                    <p>CV</p>
                <div className="w-28 h-8 grid grid-cols-2 rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <a className="grid grid-cols-2 mx-auto" href=""><BsFillCircleFill className="m-3" color="Red" />Cargar</a>
                </div>
                </div>
                </Link>


    ) : (    
        (file.map(function cv(files){
           
            if(files.key_f == 1){
               return <div key={files.user_id}>
                 <p>{files.key_F == 1 ? 'CV' : 'CV'}</p>
            <div className="w-28 h-8 grid grid-cols-2 rounded-xl bg-gray-300 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
            <a className="grid grid-cols-2 mx-auto" href={files.ruta} target="_BLANK"><BsFillCircleFill className="m-3" color="Green" />Guardado</a>
            </div>
                </div>
            }
        }
        
        )
        )
    )}
        </div>
   )
}