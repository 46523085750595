import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ING from "../Resources/test.JPG";
import config from "../config/env";

function LoginC() {
  const naviget = useNavigate();
  const [user, setUser] = useState();
  const [pass, setPass] = useState();
  const [error, setError] = useState();
  const [msg, setMsg] = useState();

  useEffect(
    () => {
      let login = localStorage.getItem("login");
      let loginA = localStorage.getItem("loginA");
      let loginT = localStorage.getItem("loginT");
      let loginE = localStorage.getItem("loginE");
      if (login) {
        naviget("/");
      } else if (loginA) {
        naviget("/");
      } else if (loginT) {
        naviget("/");
      } else if (loginE) {
        naviget("/");
      }
      let loginStatus = localStorage.getItem("loginStatus");
      if (loginStatus) {
        setError(loginStatus);
        setTimeout(function () {
          localStorage.clear();
          window.location.reload();
        }, 3000);
      }
      setTimeout(function () {
        setMsg("");
      }, 5000);
    },
    { msg }
  );

  const handleInputChange = (e, type) => {
    switch (type) {
      case "user":
        setError("");
        setUser(e.target.value);
        if (e.target.value == "") {
          setError("El usuario es incorrecto");
        }
        break;
      case "pass":
        setError("");
        setPass(e.target.value);
        if (e.target.value == "") {
          setError("Contraseña incorrecta");
        }
        break;
      default:
    }
  };

  function loginSubmit() {
    if (user !== "" && pass != "") {
      var url = `${config.BASE_URL}/API_DualSystem/validate.php`;
      var headers = {
        Accept: "aplication/json",
        "Content-type": "aplication/json",
      };
      var Data = {
        user: user,
        pass: pass,
      };
      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          if (
            response[0].result === "Correo Invalido" ||
            response[0].result === "Contraseña Invalida"
          ) {
            setError(response[0].result);
          } else if (response[0].rol == 1) {
            setTimeout(function () {
              localStorage.setItem("id", response[0].id);
              localStorage.setItem("name", response[0].name);
              localStorage.setItem("apep", response[0].apellidop);

              localStorage.setItem("loginA", true);

              naviget("/admin");
            }, 500);
          } else if (response[0].rol == 3) {
            setTimeout(function () {
              localStorage.setItem("id", response[0].id);
              localStorage.setItem("name", response[0].name);
              localStorage.setItem("apep", response[0].apellidop);
              localStorage.setItem("did", response[0].did);

              localStorage.setItem("loginT", true);

              naviget("/teacher");
            }, 500);
          } else if (response[0].rol == 4) {
            setTimeout(function () {
              localStorage.setItem("Eid", response[0].Eid);

              localStorage.setItem("loginE", true);

              naviget("/empresa");
            }, 500);
          } else {
            setMsg(response[0].result);
            setTimeout(function () {
              localStorage.setItem("id", response[0].id);
              localStorage.setItem("name", response[0].name);
              localStorage.setItem("apep", response[0].apellidop);

              localStorage.setItem("login", true);

              naviget("/home");
            }, 500);
          }
        })
        .catch((err) => {
          setError(err);
          console.log(err);
        });
    } else {
      setError("Se requiere usuario existente");
    }
  }

  return (
    <div
      className="bg-cover bg-center h-screen relative"
      style={{ backgroundImage: `url(${ING})` }}
    >
      <div className="absolute inset-x-0 mx-auto top-1/4 bg-white bg-opacity-80 p-8 rounded-lg">
        <div className="text-center">
          <h1 className="font-bold text-blue-700 text-6xl mb-7">
            Modelo de Educación Dual
          </h1>
          <div className="items-center">
            <p>
              {error !== "" ? (
                <span className="error">{error}</span>
              ) : (
                <span className="Success">{msg}</span>
              )}
            </p>

            <br />
            <input
              placeholder="Correo Institucional"
              id="co"
              type="text"
              value={user}
              onChange={(e) => handleInputChange(e, "user")}
              className="bg-gray-50 border border-gray-300 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-3 mx-auto dark:bg-slate-100 dark:border-slate-100 dark:placeholder-blue-700 dark:text-blue-700 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <br />

            <br />
            <input
              placeholder="Contraseña"
              id="p"
              type="password"
              value={pass}
              onChange={(e) => handleInputChange(e, "pass")}
              className="bg-gray-50 border border-gray-300 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-3 mx-auto dark:bg-slate-100 dark:border-slate-100 dark:placeholder-blue-700 dark:text-blue-700 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />

            <br />
            <div>
              <button
                id="env"
                onClick={loginSubmit}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-full sm:w-auto px-6 py-3 mx-auto text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Iniciar Sesión
              </button>
              <div className="mt-8">
                <Link
                  to={"Register"}
                  className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base w-full sm:w-auto px-6 py-3 mx-auto text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  registrate
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginC;