import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuT";
import TableUserT from "../components/table_userT";

export default function DocenteC() {
  return (
    <div className="mx-auto max-w">
      {/* Header */}
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">TUTOR → Bienvenido!</p>
        </div>
      </div>
      <Menu />

      <div className="w-full ml-28 flex">
        <div></div>
        <div className="mt-10">
          <p className="text-5xl font-bold text-[#233255]">Bienvenido Tutor</p>
          <div className="flex flex-cols-4">
            {" "}
            <div className="h-1 w-56 bg-blue-400"></div>
            <div className="h-1 w-56 bg-orange-500"></div>
          </div>
          <div className="w-3/4 h-20 rounded-xl mt-8 grid grid-cols-5">
            <button className="bg-blue-700 text-white rounded-xl hover:bg-slate-400 hover:text-black p-2 h-10 w-32 text-center m-2">
              <a href="http://www.itspozarica.edu.mx/" target="_blank">
                ITSPR OFICIAL
              </a>
            </button>{" "}
            <br />
            <button className="bg-blue-700 text-white rounded-xl hover:bg-slate-400 hover:text-black p-2 h-10 w-32 text-center m-2">
              <a
                href="https://itspozarica.academic.lat/Autenticacion.aspx"
                target="_blank"
              >
                ACADEMIC
              </a>
            </button>{" "}
            <br />
            <button className="bg-blue-700 text-white rounded-xl hover:bg-slate-400 hover:text-black p-2 h-10 w-32 text-center m-2">
              <a
                href="https://login.microsoftonline.com/common/oauth2/authorize?client_id=00000006-0000-0ff1-ce00-000000000000&response_type=code%20id_token&scope=openid%20profile&state=OpenIdConnect.AuthenticationProperties%3D_LE7hPtyYo-A7eCS9NhTWmcd7PshnJdZSn-FPsaQAPjwhG0BPWduEL9SyoyImZOfPinNFgn6_TA6jNCQNtYpRMV3YWrFEs1XTeFzR0E7OZrIIK2iJnmseWJhYXGr3ISorr_DLQrwOJoSe4WX8aA2QSDrTc6MK5EwL3oUDQLdJuE&response_mode=form_post&nonce=638362308194859598.NTMxZjQ5ZjItZGNkYi00NzI5LThkMzUtNzk0ZGYxMWY3NDhkMmZhYzJmYTYtZDBkYS00NjJiLWJiZGItNzUwMTViNjFmNjBl&redirect_uri=https%3A%2F%2Fportal.microsoftonline.com%2Flanding&ui_locales=es-US&mkt=es-US&client-request-id=8b9a58d4-c90a-409e-b3da-1d76689f795d&x-client-SKU=ID_NET472&x-client-ver=6.34.0.0&sso_reload=true"
                target="_blank"
              >
                Correo
              </a>
            </button>
          </div>

          <div className="w-3/4 p-5 h-auto flex flex-col rounded-xl content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
            <div className="mt-3 grid grid-cols-4 gap-2">
              <p className="title text-2xl font-bold text-left col-span-4">
                Alumnos Vinculados - Información personal
              </p>
              <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 col-span-4" />
            </div>
            <br />

            <TableUserT />
          </div>
        </div>
      </div>
    </div>
  );
}
