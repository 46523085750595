import { Link} from "react-router-dom";
import config from "../config/env";

export default function PsicoC(){
    
    let id = localStorage.getItem("id")
    const handleFormSubmit = (e) => {
        e.preventDefault();
        
        // Actualiza el atributo 'action' del formulario dinámicamente
        const form = e.target;
        form.action = `${config.BASE_URL}/API_DualSystem/question_recivdos.php?id=${id}`;
    
        // Envía el formulario
        form.submit();
      };
    
    return (
        <div className="w-5/6 h-auto mx-auto content-center m-8 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
            <div className='relative w-full h-full p-5 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]'>

                <Link className="absolute text-red font-bold" to={'/Instrument'}>X</Link>
                <p className="text-center font-bold text-cyan-950">Cuestionario Burnout</p>
                <hr className='h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700'/>
                
                <form method="POST" onSubmit={handleFormSubmit}>
                
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 1</p>
    <p className="font-semibold text-xl">Para responder las preguntas sig. considere las condiciones ambientales de su
trabajo</p>
                {/**----------------------------------------------------------------- */}
                    <div>
                      
                    <p className="font-semibold mb-2"><p>1</p>El espacio donde trabajo me permite realizar mis actividades de manera segura e
higiénica
</p>
                    <div className="flex flex-row text-[14px] gap-3 items-center">
                        <input className="" name='qn_1' type="radio" value='0' defaultChecked/>Siempre
                        <input name="qn_1" type="radio" value='1'/>Casi Siempre.
                        <input name="qn_1" type="radio" value='2'/>Algunas Veces.
                        <input name="qn_1" type="radio" value='3'/>Casi Nunca.
                        <input name="qn_1" type="radio" value='4'/>Nunca.
                       
                    </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                    </div>   <br /> <br />
                    {/**----------------------------------------------------------------- */}

                       {/**----------------------------------------------------------------- */}
                       <div>
                      
                      <p className="font-semibold mb-2"><p>2</p>Mi trabajo me exige hacer mucho esfuerzo físico</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name="qn_2" type="radio" value='4'/>Siempre
                          <input name="qn_2" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_2" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_2" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_2" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />

                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>3</p>Me preocupa sufrir un accidente en mi trabajo
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_3" type="radio" value='4'/>Siempre
                          <input name="qn_3" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_3" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_3" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_3" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>4</p>Considero que en mi trabajo se aplican las normas de seguridad y salud en el trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_4' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_4" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_4" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_4" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_4" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>5</p>Considero que las actividades que realizo son peligrosas
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_5" type="radio" value='4'/>Siempre
                          <input name="qn_5" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_5" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_5" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_5" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
                      </div>

                      <div>
    <p className="font-bold text-2xl">Sección 2</p>
    <p className="font-semibold text-xl">Para responder a las preguntas sig. piense en la cantidad y ritmo de trabajo que
tiene</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>6</p>Por la cantidad de trabajo que tengo debo quedarme tiempo adicional a mi turno</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_6" type="radio" value='4'/>Siempre
                          <input name="qn_6" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_6" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_6" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_6" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>7</p>Por la cantidad de trabajo que tengo debo trabajar sin parar</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_7" type="radio" value='4'/>Siempre
                          <input name="qn_7" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_7" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_7" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_7" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>8</p>Considero que es necesario mantener un ritmo de trabajo acelerado
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_8" type="radio" value='4'/>Siempre
                          <input name="qn_8" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_8" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_8" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_8" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 3</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con el esfuerzo mental que le exige su
trabajo.</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>9</p> Mi trabajo exige que esté muy concentrado</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_9" type="radio" value='4'/>Siempre
                          <input name="qn_9" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_9" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_9" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_9" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>10</p>Mi trabajo requiere que memorice mucha información
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_10" type="radio" value='4'/>Siempre
                          <input name="qn_10" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_10" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_10" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_10" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>11</p>En mi trabajo tengo que tomar decisiones difíciles muy rápido
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_11" type="radio" value='4'/>Siempre
                          <input name="qn_11" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_11" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_11" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_11" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>12</p>Mi trabajo exige que atienda varios asuntos al mismo tiempo
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_12" type="radio" value='4'/>Siempre
                          <input name="qn_12" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_12" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_12" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_12" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div >
    <p className="font-bold text-2xl">Sección 4</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con las actividades que realiza en su
trabajo.
</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>13</p>En mi trabajo soy responsable de cosas de mucho valor
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_13" type="radio" value='4'/>Siempre
                          <input name="qn_13" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_13" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_13" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_13" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>14</p>Respondo ante mi jefe por los resultados de toda mi área de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_14" type="radio" value='4'/>Siempre
                          <input name="qn_14" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_14" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_14" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_14" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>15</p> En el trabajo me dan órdenes contradictorias
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_15" type="radio" value='4'/>Siempre
                          <input name="qn_15" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_15" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_15" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_15" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>16</p>Considero que en mi trabajo me piden hacer cosas innecesarias</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_16" type="radio" value='4'/>Siempre
                          <input name="qn_16" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_16" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_16" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_16" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 5</p>
    <p className="font-semibold text-xl">Las preguntas siguientes están relacionadas con su jornada de trabajo</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>17</p>Trabajo horas extras más de tres veces a la semana</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_17" type="radio" value='4'/>Siempre
                          <input name="qn_17" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_17" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_17" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_17" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>18</p>Mi trabajo me exige laborar en días de descanso, festivos o fines de semana</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_18" type="radio" value='4'/>Siempre
                          <input name="qn_18" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_18" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_18" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_18" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>19</p>Considero que el tiempo en el trabajo es mucho y perjudica mis actividades familiares o
personales</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_1" type="radio" value='4'/>Siempre
                          <input name="qn_19" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_19" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_19" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_19" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>20</p>Debo atender asuntos de trabajo cuando estoy en casa</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_20" type="radio" value='4'/>Siempre
                          <input name="qn_20" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_20" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_20" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_20" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>21</p>Pienso en las actividades familiares o personales cuando estoy en mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_21" type="radio" value='4'/>Siempre
                          <input name="qn_21" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_21" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_21" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_21" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>22</p>Pienso que mis responsabilidades familiares afectan mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_22" type="radio" value='4'/>Siempre
                          <input name="qn_22" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_22" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_22" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_22" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div>
    <p className="font-bold text-2xl">Sección 6</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con las decisiones que puede tomar en su
trabajo.</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>23</p>Mi trabajo permite que desarrolle nuevas habilidades
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_23' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_23" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_23" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_23" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_23" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>24</p>En mi trabajo puedo aspirar a un mejor puesto</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_24' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_24" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_24" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_24" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_24" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>25</p>Durante mi jornada de trabajo puedo tomar pausas cuando las necesito</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_25' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_25" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_25" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_25" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_25" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>26</p>Puedo decidir cuánto trabajo realizo durante la jornada laboral
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_26' type="radio" value='0'defaultChecked/>Siempre
                          <input name="qn_26" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_26" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_26" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_26" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>27</p> Puedo decidir la velocidad a la que realizo mis actividades en mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_27' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_27" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_27" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_27" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_27" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>28</p>Puedo cambiar el orden de las actividades que realizo en mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_28' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_28" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_28" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_28" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_28" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 7</p>
    <p className="font-semibold text-xl">: Las preguntas sig. están relacionadas con cualquier tipo de cambio que ocurra</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>29</p>Los cambios que se presentan en mi trabajo dificultan mi labor</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_29" type="radio" value='4'/>Siempre
                          <input name="qn_29" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_29" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_29" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_29" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>30</p> Cuando se presentan cambios en mi trabajo se tienen en cuenta mis ideas o
aportaciones
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_30' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_30" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_30" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_30" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_30" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div>
    <p className="font-bold text-2xl">Sección 8</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con la capacitación e información
proporcionada</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>31</p>Me informan con claridad cuáles son mis funciones</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_31' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_31" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_31" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_31" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_31" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>32</p>Me explican claramente los resultados que debo obtener en mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_32' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_32" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_32" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_32" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_32" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>33</p> Me explican claramente los objetivos de mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_33' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_33" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_33" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_33" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_33" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>34</p>Me informan con quién puedo resolver problemas o asuntos de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_34' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_34" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_34" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_34" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_34" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>35</p>Me permiten asistir a capacitaciones relacionadas con mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_35' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_35" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_35" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_35" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_35" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>36</p>. Recibo capacitación útil para hacer mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_36' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_36" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_36" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_36" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_36" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 9</p>
    <p className="font-semibold text-xl">Las preguntas siguientes están relacionadas con el o los jefes con quién tiene
contacto.
</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>37</p>Mi jefe ayuda a organizar mejor el trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_37' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_37" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_37" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_37" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_37" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>38</p>Mi jefe tiene en cuenta mis puntos de vista y opiniones
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_38' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_38" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_38" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_38" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_38" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>39</p>Mi jefe me comunica a tiempo la información relacionada con el trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_39' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_39" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_39" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_39" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_39" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>40</p>. La orientación que me da mi jefe me ayuda a realizar mejor mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_40' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_40" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_40" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_40" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_40" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>41</p>. Mi jefe ayuda a solucionar los problemas que se presentan en el trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_41' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_41" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_41" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_41" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_41" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div>
    <p className="font-bold text-2xl">Sección 10</p>
    <p className="font-semibold text-xl">Las preguntas siguientes se refieren a las relaciones con sus compañeros.</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>42</p>Puedo confiar en mis compañeros de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_42' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_42" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_42" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_42" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_42" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>43</p>. Entre compañeros solucionamos los problemas de trabajo de forma respetuosa</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_43' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_43" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_43" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_43" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_43" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>44</p>En mi trabajo me hacen sentir parte del grupo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_44' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_44" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_44" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_44" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_44" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>45</p>Cuando tenemos que realizar trabajo de equipo los compañeros colaboran</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_45' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_45" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_45" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_45" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_45" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>46</p> Mis compañeros de trabajo me ayudan cuando tengo dificultades</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_46' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_46" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_46" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_46" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_46" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 11</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con la información que recibe sobre su:
Rendimiento en el trabajo, reconocimiento, el sentido de pertenencia y la estabilidad que le
ofrece su trabajo
</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>47</p>Me informan sobre lo que hago bien en mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_47' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_47" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_47" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_47" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_47" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>48</p>La forma como evalúan mi trabajo en mi centro de trabajo me ayuda a mejorar mi
desempeño</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_48' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_48" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_48" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_48" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_48" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>49</p> En mi centro de trabajo me pagan a tiempo mi salario</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_49' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_49" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_49" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_49" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_49" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>50</p>El pago que recibo es el que merezco por el trabajo que realizo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_50' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_50" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_50" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_50" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_50" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>51</p> Si obtengo los resultados esperados en mi trabajo me recompensa o reconocen</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_51' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_51" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_51" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_51" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_51" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>52</p>. Las personas que hacen bien el trabajo pueden crecer laboralmente</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_52' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_52" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_52" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_52" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_52" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>53</p>Considero que mi trabajo es estable</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_53' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_53" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_53" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_53" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_53" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>54</p>. En mi trabajo existe continua rotación de personal</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_54" type="radio" value='4'/>Siempre
                          <input name="qn_54" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_54" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_54" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_54" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>55</p> Siento orgullo de elaborar en este centro de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_55' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_55" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_55" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_55" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_55" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>56</p> Me siento comprometido con mi trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_56' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_56" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_56" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_56" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_56" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div>
    <p className="font-bold text-2xl">Sección 12</p>
    <p className="font-semibold text-xl">Las preguntas sig. Están relacionadas con actos de violencia laboral.
(malos tratos, acoso, hostigamiento, acoso psicológico).</p>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>57</p>. En mi trabajo puedo expresarme libremente sin interrupciones</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                          <input className="" name='qn_57' type="radio" value='0' defaultChecked/>Siempre
                          <input name="qn_57" type="radio" value='1'/>Casi Siempre.
                          <input name="qn_57" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_57" type="radio" value='3'/>Casi Nunca.
                          <input name="qn_57" type="radio" value='4'/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>58</p>Recibo críticas constantes a mi persona y/o trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_58" type="radio" value='4'/>Siempre
                          <input name="qn_58" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_58" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_58" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_58" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>59</p>. Recibo burlas, calumnias, difamaciones, humillaciones o ridiculizaciones</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_59" type="radio" value='4'/>Siempre
                          <input name="qn_59" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_59" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_59" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_59" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>60</p>Se ignora mi presencia o se me excluye de las reuniones de trabajo y en la toma de
decisiones</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_60" type="radio" value='4'/>Siempre
                          <input name="qn_60" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_60" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_60" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_60" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>61</p> Se manipula las situaciones de trabajo para hacerme parecer un mal trabajador</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_61" type="radio" value='4'/>Siempre
                          <input name="qn_61" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_61" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_61" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_61" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>62</p>. Se ignoran mis éxitos laborales y se atribuyen a otros trabajadores</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_62" type="radio" value='4'/>Siempre
                          <input name="qn_62" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_62" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_62" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_62" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>63</p>Me bloquean o impiden las oportunidades que tengo para obtener accesos a mejor en
mi trabajo
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_63" type="radio" value='4'/>Siempre
                          <input name="qn_63" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_63" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_63" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_63" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>64</p>He presenciado actos de violencia en mi centro de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_64" type="radio" value='4'/>Siempre
                          <input name="qn_64" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_64" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_64" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_64" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div className="bg-slate-300">
    <p className="font-bold text-2xl">Sección 13</p>
    <p className="font-semibold text-xl">Las preguntas siguientes están relacionadas con la atención a clientes y
usuarios.
Si su respuesta fue "SÍ", responda las preguntas siguientes. Si su respuesta fue "NO" pase a
las preguntas de
la sección siguiente</p>
<fieldset className="ml-6 mt-4 mb-4">
    <label htmlFor="si">Si</label>
    <input className="mr-4" type="checkbox" id="si" />
    <label htmlFor="no">No</label>
    <input type="checkbox" id="no" />
</fieldset>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>65</p>Atiendo clientes o usuarios muy enojados
</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_65" type="radio" value='4'/>Siempre
                          <input name="qn_65" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_65" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_65" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_65" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>66</p>. Mi trabajo me exige atender personas muy necesitadas de ayuda o enfermas</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_66" type="radio" value='4'/>Siempre
                          <input name="qn_66" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_66" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_66" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_66" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>67</p>Para hacer mi trabajo debo demostrar sentimientos distintos a los mios</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_67" type="radio" value='4'/>Siempre
                          <input name="qn_67" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_67" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_67" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_67" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>68</p>Mi trabajo me exige atender situaciones de violencia</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_68" type="radio" value='4'/>Siempre
                          <input name="qn_68" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_68" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_68" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_68" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
</div>
<div>
    <p className="font-bold text-2xl">Sección 14</p>
    <p className="font-semibold text-xl">Las preguntas sig. están relacionadas con las actitudes de las personas que
supervisan.
Si su respuesta fue "SÍ", responda las preguntas siguientes. Si su respuesta fue "NO", ha
concluido el cuestionario.</p>

<fieldset className="ml-6 mt-4 mb-4">
    <label htmlFor="si">Si</label>
    <input className="mr-4" type="checkbox" id="si" />
    <label htmlFor="no">No</label>
    <input type="checkbox" id="no" />
</fieldset>
                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>69</p>Comunican tarde los asuntos de trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_69" type="radio" value='4'/>Siempre
                          <input name="qn_69" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_69" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_69" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_69" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>70</p>. Dificultan el logro de los resultados del trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_70" type="radio" value='4'/>Siempre
                          <input name="qn_70" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_70" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_70" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_70" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>71</p>. Cooperan poco cuando se necesita</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_71" type="radio" value='4'/>Siempre
                          <input name="qn_71" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_71" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_71" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_71" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}

                      {/**----------------------------------------------------------------- */}
                    <div>
                      
                      <p className="font-semibold mb-2"><p>72</p>Ignoran las sugerencias para mejorar su trabajo</p>
                      <div className="flex flex-row text-[14px] gap-3 items-center">
                      <input className="" name="qn_72" type="radio" value='4'/>Siempre
                          <input name="qn_72" type="radio" value='3'/>Casi Siempre.
                          <input name="qn_72" type="radio" value='2'/>Algunas Veces.
                          <input name="qn_72" type="radio" value='1'/>Casi Nunca.
                          <input name="qn_72" type="radio" value='0' defaultChecked/>Nunca.
                         
                      </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                      </div>   <br /> <br />
                      {/**----------------------------------------------------------------- */}
                      </div>
                    <button type="submit">Finalizar</button>
                    
                </form>
               
                
            
                     
            </div>
        </div>
        

        
    );
}