import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";

export default function SocialC() {
  return (
    <div className="container">
      <Menu />
      <h1>Social</h1>
      <p>Pagina Nueva</p>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
