import { Link } from "react-router-dom";
import { IoMdNotificationsOutline, IoMdArrowDropdown, IoIosLogOut } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

//Icons import
import { BiSolidDashboard, BiSolidMessageSquareDots, BiBarChart, BiDetail, BiCog, BiSolidInfoCircle } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";
import { MdOutlineAbc } from "react-icons/md";
import { PiChalkboardTeacherFill, PiExamBold} from "react-icons/pi";
import { FaUserGraduate, FaFileSignature, FaClipboardList } from "react-icons/fa6";
/**-------------- */

let name = localStorage.getItem("name");
export default function Menu(){
  const naviget = useNavigate();
    function logoutSubmit(){
        
        localStorage.setItem("loginT", false);
        localStorage.setItem("loginStatus", "Sesión Cerrada Correctamente");
       
        naviget("/");
    }
  
    return (
        <div
          className="fixed rounded-full top-28 left-6  w-12 m-1
          flex flex-col bg-[#233255] text-white shadow-lg"
        >
          <Link to={"/teacher"}>
          <SideBarIcon icon={<BiSolidDashboard color="white" size="18" />} text="Dashboard" />
          </Link>

          
          <Link to={"/BoletaT"}>
          <SideBarIcon icon={<BiDetail color="white" size="18" />} text="Calificaciones" />
          </Link>
          <Link to={"/ReportesT"}>
          <SideBarIcon icon={<FaFileSignature color="white" size="18" />} text="Reportes" />
          </Link>
          
          <br />
          <br />
          <Link onClick={logoutSubmit}>
          <SideBarIcon icon={<IoIosLogOut color="white" size="18" />} text="Config" />
          </Link>
        </div>
      );
}

const SideBarIcon = ({ icon, text = "Option" }) => (
    <div className="sidebar-icon group bg-transparent hover:bg-[#F6AD2B]">
      {icon}
      <spam className="sidebar-tooltip group-hover:scale-100 ">{text}</spam>
    </div>
  );