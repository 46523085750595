import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuE";
import UserId from "../components/log";

import { Outlet } from "react-router-dom";
import TableUserE from "../components/table_userE";
export default function DocumentsE() {
  return (
    <div className="mx-auto max-w">
      <div className="w-full flex space-x-[75%] items-center">
        {/* Header */}
        <div className="mb-8 w-full bg-gray-800 text-white py-4 flex items-center justify-between">
          <div className="ml-6 flex items-center">
            <img src={TECN} alt="logotec" className="w-16" />
            <p className="text-xl font-bold ml-2">EMPRESA → Bienvenido!</p>
          </div>
        </div>
      </div>
      <Menu />

      <div className="w-5/6 h-full grid mx-auto">
        {/**CONTENT DIV----------------------- */}

        <div className="w-full p-5 mb-7 h-auto flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
          <div className="grid grid-cols-4 gap-2">
            <p className="title text-2xl font-bold text-left col-span-4">
              Alumnos Vinculados - Información personal
            </p>
          </div>
          <br />

          <div>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
            <TableUserE />
          </div>
        </div>

        <Outlet />
      </div>
      {/**CONTENT DIV------------------END----- */}
    </div>
  );
}
