import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config/env";

export default function TableUser() {
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [searchPeriod, setSearchPeriod] = useState('');

  const carreras = [
    { id: 111, name: 'Ing. Sistemas Computacionales' },
    { id: 222, name: 'Ing. Industrial' },
    { id: 333, name: 'Ing. Gestión Empresarial' },
    { id: 444, name: 'Ing. Nanotecnología' },
    { id: 555, name: 'Ing. Mecatrónica' },
    { id: 666, name: 'Ing. Electromecánica' },
    { id: 777, name: 'Ing. Electrónica' },
    { id: 888, name: 'Ing. Geociencias' },
    { id: 999, name: 'Lic. Contabilidad' },
    // Agrega el resto de las opciones de carrera aquí
  ];

  const semestres = [
    { id: 1, name: 'Primero' },
    { id: 2, name: 'Segundo' },
    { id: 3, name: 'Tercero' },
    { id: 4, name: 'Cuarto' },
    { id: 5, name: 'Quinto' },
    { id: 6, name: 'Sexto' },
    { id: 7, name: 'Séptimo' },
    { id: 8, name: 'Octavo' },
    { id: 9, name: 'Noveno' },
    { id: 10, name: 'Décimo' },
    { id: 11, name: 'Onceavo' },
    { id: 12, name: 'Doceavo' },
    { id: 0, name: 'No cursando' },
    // Agrega el resto de las opciones de semestre aquí
  ];

  const fetchData = async () => {
    try {
      const userResponse = await axios.get(`${config.BASE_URL}/API_DualSystem/userE.php?id=${localStorage.getItem("Eid")}`);
      setUserData(userResponse.data);
      setFilteredUserData(userResponse.data); // Inicializamos los datos filtrados con todos los datos
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Filtrar usuarios basado en el término de búsqueda, el periodo y el filtro seleccionado
    const filteredUsers = userData.filter(user => {
      const fullName = `${user.name ?? ''} ${user.last_f ?? ''} ${user.last_m ?? ''}`.toLowerCase();
      const controlNumber = (user.N_control ?? '').toString().toLowerCase();
      let matchFilter = true;

      if (filterBy === 'carrera') {
        matchFilter = user.id_carrera?.toString() === filterValue?.toString();
      } else if (filterBy === 'semestre') {
        matchFilter = user.id_semestre?.toString() === filterValue?.toString();
      }
       // Filtrar también por periodo
      const matchPeriod = (user.periodo ?? '').toLowerCase().includes(searchPeriod);
      return (fullName.includes(searchTerm.toLowerCase()) || controlNumber.includes(searchTerm.toLowerCase())) && matchFilter && matchPeriod;
    });
    setFilteredUserData(filteredUsers);
  }, [searchTerm, userData, filterBy, filterValue, searchPeriod]);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    const name = event.target.name; // Obtener el nombre del campo
    if (name === 'name') {
      setSearchTerm(value);
    } else if (name === 'period') { // Utilizar el nombre del campo para el periodo
      setSearchPeriod(value);
    }
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setFilterValue('');
    setSearchTerm('');
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
    setSearchTerm('');
  };

  const handleClearFilters = () => {
    setFilterBy('');
    setFilterValue('');
    setSearchTerm('');
  };

  return (
    <div className="">
      <form>
        {/* Fragmento agregado */}
        {userData.map((user, index) => (
          <React.Fragment key={user.ID_student}>
            {index === 0 && (
              <div className="mb-4">
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Tutor Académico
                  </label>
                  <input
                    readOnly
                    style={{
                      minWidth: `${(user.name_a.length + user.apep_a.length + user.apem_a.length + 1) * 9}px`,
                    }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={`${user.name_a} ${user.apep_a} ${user.apem_a}`}
                  />
                </div>
  
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Carrera
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '270px' }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      user.id_carrera_a == 111
                        ? 'Ing. Sistemas Computacionales'
                        : user.id_carrera_d == 222
                        ? 'Ing. Industrial'
                        : user.id_carrera_a == 333
                        ? 'Ing. Gestión Empresarial'
                        : user.id_carrera_a == 444
                        ? 'Ing. Nanotecnología'
                        : user.id_carrera_a == 555
                        ? 'Ing. Mecatrónica'
                        : user.id_carrera_a == 666
                        ? 'Ing. Electromecánica'
                        : user.id_carrera_a == 777
                        ? 'Ing. Electrónica'
                        : user.id_carrera_a == 888
                        ? 'Ing. Geociencias'
                        : user.id_carrera_a == 999
                        ? 'Lic. Contabilidad'
                        : 'Ingeniero'
                    }
                  />
                </div>
  
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Ubicación
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${user.adress_a.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={user.adress_a}
                  />
                </div>
  
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Empresa
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${user.empresa_a.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={user.empresa_a}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
  
        {/* Sección de búsqueda y filtros */}
        <div className="flex flex-col-4 w-3/4 h-auto">
          <div className="col-span-3 mr-4">
            <input
              type="text"
              placeholder="Nombre o No. de control"
              value={searchTerm}
              onChange={handleSearchChange}
              name="name"
              className="h-8 w-50 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
            />
            <input
              type="text"
              name="period"
              placeholder="Periodo"
              value={searchPeriod}
              onChange={handleSearchChange}
              className="h-8 w-50 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
            />
          </div>
  
          {/* Sección de filtros */}
          <div className="col-span-3 ml-4 mr-4 h-auto">
            <select
              value={filterBy}
              onChange={handleFilterChange}
              className="h-10 p-2 bg-gray-200 rounded-lg"
            >
              <option value="">Filtrar por</option>
              <option value="carrera">Carrera</option>
              <option value="semestre">Semestre</option>
            </select>
  
            {/* Opciones de filtro */}
            {filterBy === 'carrera' && (
              <select
                value={filterValue}
                onChange={handleFilterValueChange}
                className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
              >
                <option value="">Seleccionar Carrera</option>
                {carreras.map(carrera => (
                  <option key={carrera.id} value={carrera.id}>
                    {carrera.name}
                  </option>
                ))}
              </select>
            )}
  
            {filterBy === 'semestre' && (
              <select
                value={filterValue}
                onChange={handleFilterValueChange}
                className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
              >
                <option value="">Seleccionar Semestre</option>
                {semestres.map(semestre => (
                  <option key={semestre.id} value={semestre.id}>
                    {semestre.name}
                  </option>
                ))}
              </select>
            )}
  
            {/* Botón para limpiar filtros */}
            <button
              onClick={handleClearFilters}
              className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
  
        {/* Tabla de datos de usuarios */}
        <div className="grid grid-cols-3 mt-4">
          <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
              <tr>
                <th scope="col" className="px-6 py-3">Nombre Completo</th>
                <th scope="col" className="px-6 py-3">No° Control</th>
                <th scope="col" className="px-6 py-3">Curp</th>
                <th scope="col" className="px-6 py-3">Teléfono</th>
                <th scope="col" className="px-6 py-3">Carrera</th>
                <th scope="col" className="px-6 py-3">Semestre</th>
                <th scope="col" className="px-6 py-3">Periodo</th>
              </tr>
            </thead>
            <tbody>
              {filteredUserData.map(user => (
                <tr key={user.ID_student} className="bg-gray-300 border-b">
                  <td className="px-6 py-4">{`${user.name} ${user.last_f} ${user.last_m}`}</td>
                  <td className="px-6 py-4">{user.N_control}</td>
                  <td className="px-6 py-4">{user.curp}</td>
                  <td className="px-6 py-4">{user.phone}</td>
                  <td className="px-6 py-4">
                    {user.id_carrera == 111
                      ? 'Ing. Sistemas Computacionales'
                      : user.id_carrera == 222
                      ? 'Ing. Industrial'
                      : user.id_carrera == 333
                      ? 'Ing. Gestión Empresarial'
                      : user.id_carrera == 444
                      ? 'Ing. Nanotecnología'
                      : user.id_carrera == 555
                      ? 'Ing. Mecatrónica'
                      : user.id_carrera == 666
                      ? 'Ing. Electromecánica'
                      : user.id_carrera == 777
                      ? 'Ing. Electrónica'
                      : user.id_carrera == 888
                      ? 'Ing. Geociencias'
                      : user.id_carrera == 999
                      ? 'Lic. Contabilidad'
                      : 'Ingeniero'}
                  </td>
                  <td className="px-6 py-4">
                    {user.id_semestre == 1
                      ? 'primero'
                      : user.id_semestre == 2
                      ? 'Segundo'
                      : user.id_semestre == 3
                      ? 'Tercero'
                      : user.id_semestre == 4
                      ? 'Cuarto'
                      : user.id_semestre == 5
                      ? 'Quinto'
                      : user.id_semestre == 6
                      ? 'Sexto'
                      : user.id_semestre == 7
                      ? 'Séptimo'
                      : user.id_semestre == 8
                      ? 'Octavo'
                      : user.id_semestre == 9
                      ? 'Noveno'
                      : user.id_semestre == 10
                      ? 'Décimo'
                      : user.id_semestre == 11
                      ? 'Onceavo'
                      : user.id_semestre == 12
                      ? 'Doceavo'
                      : 'No cursando'}
                  </td>
                  <td className="px-6 py-4">{user.periodo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}
