import { Link } from "react-router-dom";

//Icons import
import { BiSolidDashboard, BiSolidMessageSquareDots, BiBarChart, BiDetail, BiCog, BiSolidInfoCircle } from "react-icons/bi";
import { GiTeacher } from "react-icons/gi";
import { MdOutlineAbc } from "react-icons/md";
import { PiChalkboardTeacherFill, PiExamBold} from "react-icons/pi";
import { FaUserGraduate, FaFileSignature, FaClipboardList } from "react-icons/fa6";
import { SiFiles } from "react-icons/si";
import { IoMdNotificationsOutline, IoMdArrowDropdown, IoMdLogOut } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
let name = localStorage.getItem("name");
/**-------------- */


export default function Menu(){
  const naviget = useNavigate();
    function logoutSubmit(){
        
        localStorage.setItem("login", false);
        localStorage.setItem("loginStatus", "Sesión Cerrada Correctamente");
       
        naviget("/");
    }
  
    return (
        <div
          className="fixed rounded-full top-28 left-6  w-12 m-1
        flex flex-col bg-[#233255] text-white shadow-lg"
        >
          <Link to={"/home"}>
          <SideBarIcon icon={<BiSolidDashboard color="White" size="18" />} text="Dashboard" />
          </Link>

          <Link to={"/Documents"}>
          <SideBarIcon icon={<FaClipboardList color="white" size="18" />} text="Documentos" />
          </Link>
          <Link to={"/Instrument"}>
          <SideBarIcon icon={<PiExamBold color="white" size="18" />} text="Instrumentos" />
          </Link>
          


          <Link to={"/Boleta"}>
          <SideBarIcon icon={<BiDetail color="white" size="18" />} text="Calificaciones" />
          </Link>

          <Link to={"/Reportes"}>
          <SideBarIcon icon={<FaFileSignature color="white" size="18" />} text="Reportes" />
          </Link>
          


          
          <br />
          <br />
          <Link onClick={logoutSubmit}>
          <SideBarIcon icon={<IoMdLogOut color="white" size="18" />} text="Cerrar Sesión" />
          </Link>
        </div>
      );
}

const SideBarIcon = ({ icon, text = "Option" }) => (
    <div className="sidebar-icon group bg-transparent hover:bg-[#F6AD2B]">
      {icon}
      <spam className="sidebar-tooltip group-hover:scale-100 ">{text}</spam>
    </div>
  );

  /*----------LINKS




          <Link to={"/Subjects"}>
          <SideBarIcon icon={<MdOutlineAbc color="white" size="18" />} text="Subjects" />
          </Link>

          <Link to={"/Teachers"}>
          <SideBarIcon icon={<PiChalkboardTeacherFill color="white" size="18" />} text="Teachers" />
          </Link>

          <Link to={"/Social"}>
          <SideBarIcon icon={<FaUsers color="white" size="18" />} text="Social" />
          </Link>

          <Link to={"/Messages"}>
          <SideBarIcon icon={<BiSolidMessageSquareDots color="white" size="18" />} text="Messages" />
          </Link>

          <Link to={"/Analytics"}>
          <SideBarIcon icon={<BiBarChart color="white" size="18" />} text="Analytics" />
          </Link>

          <Link to={"/Reports"}>
          <SideBarIcon icon={<BiSolidInfoCircle color="white" size="18" />} text="Reports" />
          </Link>

   */