import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import TECN from "../Resources/TECNpng.png";
import UserId from "../components/userdiv";
import InfoC from "./Info";

let name = localStorage.getItem("name");
let apep = localStorage.getItem("apep");

export default function InstrumentC() {
  return (
    <div className="mx-auto max-w">
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">ESTUDIANTE → Bienvenido.</p>
          <p className="text-xl font-bold ml-2">
            {name} {apep}
          </p>
        </div>
      </div>

      <div className="w-full flex space-x-[75%] items-center">
        <UserId />
      </div>
      <Menu />
      <InfoC />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
