import TECN from "../Resources/TECNpng.png";
import Menu from "../components/Menu";
import UserId from "../components/userdiv";
import { useDropzone } from "react-dropzone";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { BiSolidReport } from "react-icons/bi";
import { FaRegCircleXmark } from "react-icons/fa6";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import config from "../config/env";

let idd = localStorage.getItem("id");
let name = localStorage.getItem("name");
let apep = localStorage.getItem("apep");

const ReportsS = ({ classDrop }) => {
  const [modalOpenUser, setModalOpen] = useState(false); // Estado del primer modal
  // Estado del modal para docente
  const [user, setUser] = useState([]);

  const UserStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/p1.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((us) => {
        setUser(us.data);
      });
  };

  useEffect(() => {
    UserStatus();
  }, []);

  const openModalUser = () => {
    setModalOpen(true);
  };

  const closeModalUser = () => {
    setModalOpen(false);
  };

  {
    /**----------------------------------------------------------- */
  }

  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      setFiles((PreviousFiles) => [
        ...PreviousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
    if (rejectedFiles?.length) {
      setRejected((PreviousFiles) => [...PreviousFiles, ...rejectedFiles]);
    }
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    /**NEW CODE FROR TESTING---------------------------------------------------*/
    accept: {
      "aplication/pdf": [".pdf"],
    },
    maxSize: 1080 * 1920,
    onDrop,
  });
  /**--------------------------------------------------- */

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const removeAll = () => {
    setFiles([]);
    setRejected([]);
  };

  const removeRejected = (name) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!files?.length) return;

    const formData = new FormData();

    files.forEach((file) => formData.append("file", file));
    formData.append("id", idd);

    const selectedParcial = e.target.querySelector(
      'select[name="parcial"]'
    ).value;
    formData.append("parcial", selectedParcial);
    fetch(`${config.BASE_URL}/API_DualSystem/dropP1.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === "success") {
          // Verifica si la respuesta es "success"
          window.location.href = `${config.BASE_URL}/API_DualSystem/Reportes`;
        }
      });
  };

  return (
    <div className="mx-auto max-w">
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">ESTUDIANTE → Bienvenido.</p>
          <p className="text-xl font-bold ml-2">
            {name} {apep}
          </p>
        </div>
      </div>

      <div className="mt-8 w-full h-full flex space-x-[75%] items-center">
        <UserId />
      </div>
      <Menu />
      <div className="w-5/6 h-full grid mx-auto">
        <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
            <tr>
              <th scope="col" className="px-6 py-3 w-6">
                Agregar Reporte
              </th>
              <th scope="col" className="px-6 py-3 w-6">
                Reportes Subidos:
              </th>
            </tr>
          </thead>

          <tbody>
            <tr className="bg-gray-300 border-b">
              <td className="px-6 py-4 w-6">
                <div className="gap-2 m-4">
                  {/* Botón y modal para usuario */}
                  <button
                    onClick={openModalUser}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-3 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Agregar Reporte
                  </button>
                  <Modal
                    style="margin: 0 auto;"
                    isOpen={modalOpenUser}
                    onRequestClose={closeModalUser}
                  >
                    {/* Contenido del Modal para User */}
                    <button
                      onClick={closeModalUser}
                      className="text-red-600 font-extraboldbold text-4xl float-right"
                    >
                      X
                    </button>
                    <div className="gap-2 grid grid-cols-4">
                      <div className="col-span-4">
                        <p className="font-bold text-blue-950">Subir Reporte</p>
                        <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                      </div>
                      {/* ------------------------------------------------------------ */}

                      <form onSubmit={handleSubmit}>
                        <input
                          name="usuario"
                          type="hidden"
                          value={localStorage.getItem("id")}
                        />
                        <div
                          {...getRootProps({
                            classDrop: classDrop,
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="flex flex-col items-center justify-center gap-4">
                            <BsFillCloudArrowUpFill color="black" size={80} />
                            {isDragActive ? (
                              <p>Suelta los archivos</p>
                            ) : (
                              <p>Arrastra tu documentos aqui:</p>
                            )}
                          </div>
                        </div>

                        <div className="mt-4 grid">
                          <label htmlFor="p">Parcial</label>
                          <select
                            className="w-auto bg-slate-200"
                            name="parcial"
                            id="p"
                          >
                            <option value="#"></option>
                            <option value="1">Semana 1</option>
                            <option value="2">Semana 2</option>
                            <option value="3">Semana 3</option>
                            <option value="4">Semana 4</option>
                            <option value="5">Semana 5</option>
                            <option value="6">Semana 6</option>
                            <option value="7">Semana 7</option>
                            <option value="8">Semana 8</option>
                            <option value="9">Semana 9</option>
                            <option value="10">Semana 10</option>
                            <option value="11">Semana 11</option>
                            <option value="12">Semana 12</option>
                            <option value="13">Semana 13</option>
                            <option value="14">Semana 14</option>
                            <option value="15">Semana 15</option>
                            <option value="16">Semana 16</option>
                            <option value="17">Semana 17</option>
                            <option value="18">Semana 18</option>
                            <option value="19">Semana 19</option>
                            <option value="20">Semana 20</option>
                            <option value="21">Semana 21</option>
                            <option value="22">Semana 22</option>
                            <option value="23">Semana 23</option>
                            <option value="24">Semana 24</option>
                          </select>
                        </div>
                        <input
                          name="usuario"
                          type="hidden"
                          value={localStorage.getItem("id")}
                        />

                        {/* Preview */}
                        <section className="mt-10">
                          <div className="flex gap-4">
                            <button
                              type="button"
                              onClick={removeAll}
                              className="mt-1 text-[10px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors"
                            >
                              Remove all files
                            </button>
                            <button
                              type="submit"
                              id="send"
                              className="ml-auto mt-1 text-[10px] uppercase tracking-wider font-bold text-neutral-500 border border-purple-400 rounded-md px-3 hover:bg-purple-400 hover:text-white transition-colors"
                            >
                              Subir
                            </button>
                          </div>

                          {/* Accepted files */}
                          <h3 className="title text-base font-semibold text-neutral-600 mt-5 border-b pb-3">
                            Vista Previa
                          </h3>
                          <ul className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-10">
                            {files.map((file) => (
                              <li
                                key={file.name}
                                className="relative h-32 rounded-md shadow-lg"
                              >
                                <iframe
                                  src={file.preview}
                                  alt={file.name}
                                  width={100}
                                  height={100}
                                  onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                  }}
                                  className="h-full w-full object-contain rounded-md"
                                  title="file"
                                ></iframe>
                                <button
                                  type="button"
                                  className="w-7 h-7 border border-secondary-400 bg-secondary-400 rounded-full flex justify-center items-center absolute -top-3 -right-3 hover:bg-white transition-colors"
                                  onClick={() => removeFile(file.name)}
                                >
                                  <FaRegCircleXmark className="w-5 h-5 fill-white hover:fill-secondary-400 transition-colors" />
                                </button>
                                <p className="mt-2 text-neutral-500 text-[12px] font-medium">
                                  {file.name}
                                </p>
                              </li>
                            ))}
                          </ul>

                          {/* Rejected Files */}
                          <h3 className="title text-base font-semibold text-neutral-600 mt-24 border-b pb-3">
                            Archivos denegados
                          </h3>
                          <ul className="mt-6 flex flex-col">
                            {rejected.map(({ file, errors }) => (
                              <li
                                key={file.name}
                                className="flex items-start justify-between"
                              >
                                <div>
                                  <p className="mt-2 text-neutral-500 text-sm font-medium">
                                    {file.name}
                                  </p>
                                  <ul className="text-[12px] text-red-400">
                                    {errors.map((error) => (
                                      <li key={error.code}>{error.message}</li>
                                    ))}
                                  </ul>
                                </div>
                                <button
                                  type="button"
                                  className="mt-1 py-1 text-[12px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors"
                                  onClick={() => removeRejected(file.name)}
                                >
                                  remove
                                </button>
                              </li>
                            ))}
                          </ul>
                        </section>

                        <iframe id="contenido" title="cont"></iframe>
                      </form>

                      {/* -------------------------------------------------------------*/}
                    </div>
                    {/* ... (código existente) */}
                  </Modal>
                </div>
              </td>
              <td>
                {user.map((us) => (
                  <tr className="mt-2" key={us.id_file}>
                    <td className="mt-10">
                      <a
                        className="font-bold text-blue-900 text-xl"
                        href={us.ruta}
                        target="_blank"
                      >
                        {us.parcial === 1
                          ? `Semana 1`
                          : us.parcial === 2
                          ? `Semana 2`
                          : us.parcial === 3
                          ? `Semana 3`
                          : us.parcial === 4
                          ? `Semana 4`
                          : us.parcial === 5
                          ? `Semana 5`
                          : us.parcial === 6
                          ? `Semana 6`
                          : us.parcial === 7
                          ? `Semana 7`
                          : us.parcial === 8
                          ? `Semana 8`
                          : us.parcial === 9
                          ? `Semana 9`
                          : us.parcial === 10
                          ? `Semana 10`
                          : us.parcial === 11
                          ? `Semana 11`
                          : us.parcial === 12
                          ? `Semana 12`
                          : us.parcial === 13
                          ? `Semana 13`
                          : us.parcial === 14
                          ? `Semana 14`
                          : us.parcial === 15
                          ? `Semana 15`
                          : us.parcial === 16
                          ? `Semana 16`
                          : us.parcial === 17
                          ? `Semana 17`
                          : us.parcial === 18
                          ? `Semana 18`
                          : us.parcial === 19
                          ? `Semana 19`
                          : us.parcial === 20
                          ? `Semana 20`
                          : us.parcial === 21
                          ? `Semana 21`
                          : us.parcial === 22
                          ? `Semana 22`
                          : us.parcial === 23
                          ? `Semana 23`
                          : us.parcial === 24
                          ? `Semana 24`
                          : "Fuera de rango"}

                        <p
                          style={{
                            color:
                              us.status === 1
                                ? "red"
                                : us.status === 2
                                ? "green"
                                : "black",
                            fontSize: "smaller",
                          }}
                        >
                          {us.status === 1
                            ? "NO REVISADO"
                            : us.status === 2
                            ? "REVISADO POR EMPRESA"
                            : ""}
                        </p>
                        {/**------------------------------------------ */}

                        <p
                          style={{
                            color:
                              us.status_t === 1
                                ? "red"
                                : us.status_t === 2
                                ? "green"
                                : "black",
                            fontSize: "smaller",
                          }}
                        >
                          {us.status_t === 1
                            ? "NO REVISADO"
                            : us.status_t === 2
                            ? "REVISADO POR DOCENTE"
                            : ""}
                        </p>

                        {/**----------------------------------------- */}

                        <BiSolidReport color="orange" />
                      </a>
                    </td>
                    <td>
                      <form
                        method="POST"
                        action={`${config.BASE_URL}/API_DualSystem/deleteF.php`}
                      >
                        <input name="id" type="hidden" value={us.id_file} />
                        <button className="bg-red-600 font-bold p-2 m-4">
                          Eliminar
                        </button>
                      </form>
                    </td>
                  </tr>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportsS;
