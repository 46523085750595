import { Link } from "react-router-dom";
//Icons import
import { BiSolidDashboard, BiCog,} from "react-icons/bi";
import { PiChalkboardTeacherFill, PiExamBold} from "react-icons/pi";
import { BsPersonFillAdd } from "react-icons/bs";
/**-------------- */


export default function Menu(){
  
  
    return (
        <div
          className="fixed rounded-full top-16 left-6  w-12 m-1
        flex flex-col bg-[#233255] text-white shadow-lg"
        >
          <Link to={"/admin"}>
          <SideBarIcon icon={<BiSolidDashboard color="#F6AD2B" size="18" />} text="Dashboard" />
          </Link>

          <Link to={"/add_user"}>
          <SideBarIcon icon={<BsPersonFillAdd color="white" size="18" />} text="Vinculaciones" />
          </Link>
          <br />
          <br />
          <Link to={"/Config"}>
          <SideBarIcon icon={<BiCog color="white" size="18" />} text="Config" />
          </Link>
        </div>
      );
}

const SideBarIcon = ({ icon, text = "Option" }) => (
    <div className="sidebar-icon group bg-transparent hover:bg-[#F6AD2B]">
      {icon}
      <spam className="sidebar-tooltip group-hover:scale-100 ">{text}</spam>
    </div>
  );