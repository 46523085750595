import Menu from "../components/Menu";
import TECN from "../Resources/TECNpng.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosTimer } from "react-icons/io";
import FilesUser from "../components/files_table_student";
import config from "../config/env";

export default function Home() {
  let name = localStorage.getItem("name");
  let apep = localStorage.getItem("apep");

  const [burn, setBurn] = useState([]);
  const [fecha, setFecha] = useState(new Date());

  const burnStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/burn.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((db) => {
        setBurn(db.data);
      });
  };

  useEffect(() => {
    burnStatus();
  }, []);

  useEffect(() => {
    // Actualiza la fecha cada segundo
    const intervalID = setInterval(() => {
      setFecha(new Date());
    }, 1000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  // Formatea la fecha en un formato legible por humanos
  const fechaFormateada = fecha.toLocaleDateString();

  return (
    <div className="mx-auto max-w">
      {/* Header */}
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">ESTUDIANTE → Bienvenido,</p>
          <p className="text-xl font-bold ml-2">
            {name} {apep}
          </p>
        </div>
      </div>

      {/* Menu */}
      <Menu />

      {/* Sección de Inicio */}
      <div className="ml-28">
        <div className="flex items-center mt-6">
          <div>
            <p className="text-5xl font-bold text-[#233255]">Inicio</p>
            <div className="p-3 absolute w-96">
              <hr className="h-1 bg-gradient-to-r from-blue-400 to-orange-500" />
            </div>
            <div className="p-5"></div>
          </div>
        </div>
      </div>

      {/* Sección Principal con fondo gris */}
      <div className="mx-28 bg-gray-200 p-4  rounded-xl">
        <p className="text-gray-800 font-bold text-base text-left mb-7">
          PENDIENTES:
        </p>
        {burn.length === 0 ? (
          <div className="grid-flow-row place-items-start mt-4 ml-5 mb-10">
            {/* Contenido cuando no hay datos... */}

            <div className="flex items-center">
              {/* Sección lateral con el componente FilesUser */}
              <div className="w-64 h-72 p-4 rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <FilesUser />
              </div>

              <Link to={"/Instrument/mbi"}>
                <div className="w-96 p-4 ml-10 h-44 rounded-xl content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                  <p className="title text-base font-bold text-left text-blue-900 m-1">
                    Completar Maslach Burnout Inventory (MBI)
                  </p>
                  <p className="text-xs text-gray-800 text-left">
                    Este test pretende medir la frecuencia y la intensidad con
                    la que se sufre el Burnout.
                  </p>
                  <div className="flex mt-3 items-center">
                    <p className="text-sm text-gray-700 font-semibold">
                      {fechaFormateada}
                    </p>
                    <p className="ml-4 text-orange-600 font-semibold text-sm">
                      10 minutos
                    </p>
                    <IoIosTimer color="orange" size={"2rem"} />
                  </div>
                  <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />
                </div>
              </Link>
            </div>
          </div>
        ) : (
          burn.map(function mbiC(mbicheck) {
            return (
              <div className="grid place-items-center" key={mbicheck.id}>
                {/* Contenido basado en los datos... */}
                {mbicheck.burnout === 0 ? (
                  <div className="w-96 p-5 h-80 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <p className="title text-base font-bold text-center text-blue-900 m-3">
                      COMPLETADO: MBI
                    </p>
                    <p className="title text-base font-semibold text-gray-800">
                      Resultados:
                    </p>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Cansancio emocional</th>
                          <th>Despersonalización</th>
                          <th>Realización personal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center text-orange-600">
                          <td>
                            {mbicheck.cansancio_e <= 18
                              ? "Bajo"
                              : mbicheck.cansancio_e <= 26
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.cansancio_e}
                            </p>
                          </td>
                          <td>
                            {mbicheck.despersonalizacion <= 5
                              ? "Bajo"
                              : mbicheck.despersonalizacion <= 9
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.despersonalizacion}
                            </p>
                          </td>
                          <td>
                            {mbicheck.r_personal <= 33
                              ? "Bajo"
                              : mbicheck.r_personal <= 39
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.r_personal}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="m-3 text-center font-bold text-green-500 text-2xl">
                      ¡Felicidades, sigue así! :D
                    </p>
                    <p className="text-center font-light text-base text-gray-950">
                      No cuentas con indicios del síndrome de Burnout
                    </p>
                  </div>
                ) : (
                  <div className="w-96 p-5 h-80 flex flex-col rounded-xl content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <p className="title text-base font-bold text-center text-blue-900 m-3">
                      COMPLETADO: MBI
                    </p>
                    <p className="title text-base font-semibold text-gray-800">
                      Resultados:
                    </p>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Cansancio emocional</th>
                          <th>Despersonalización</th>
                          <th>Realización personal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center text-orange-600">
                          <td>
                            {mbicheck.cansancio_e <= 18
                              ? "Bajo"
                              : mbicheck.cansancio_e <= 26
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.cansancio_e}
                            </p>
                          </td>
                          <td>
                            {mbicheck.despersonalizacion <= 5
                              ? "Bajo"
                              : mbicheck.despersonalizacion <= 9
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.despersonalizacion}
                            </p>
                          </td>
                          <td>
                            {mbicheck.r_personal <= 33
                              ? "Bajo"
                              : mbicheck.r_personal <= 39
                              ? "Medio"
                              : "Alto"}
                            <p className="text-regular text-orange-900 mb-4">
                              {mbicheck.r_personal}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="m-3 text-center font-bold text-green-500 text-2xl">
                      Diagnóstico: Indicios de síndrome de Burnout.
                    </p>
                    <p className="text-center font-light text-base text-gray-950">
                      ¡Necesitas un descanso!
                    </p>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
