import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { FaRegCircleXmark } from "react-icons/fa6";
import config from "../config/env";

let idd = localStorage.getItem("id");
const Dropzone = ({ classDrop }) => {
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState([]);

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length) {
      setFiles((PreviousFiles) => [
        ...PreviousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
    if (rejectedFiles?.length) {
      setRejected((PreviousFiles) => [...PreviousFiles, ...rejectedFiles]);
    }
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    /**NEW CODE FROR TESTING---------------------------------------------------*/
    accept: {
      "aplication/pdf": [".pdf"],
    },
    maxSize: 1080 * 1920,
    onDrop,
  });
  /**--------------------------------------------------- */

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const removeFile = (name) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const removeAll = () => {
    setFiles([]);
    setRejected([]);
  };

  const removeRejected = (name) => {
    setRejected((files) => files.filter(({ file }) => file.name !== name));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!files?.length) return;

    const formData = new FormData();

    files.forEach((file) => formData.append("file", file));
    formData.append("id", idd);

    fetch(`${config.BASE_URL}/API_DualSystem/fileRecive.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          console.log(res);
          /*window.location.reload()*/
        }
      });
  };

  /**-------------------<img src={file.preview} alt='' width={100} height={100} />-------------------------------- */

  return (
    <form onSubmit={handleSubmit}>
      <div
        {...getRootProps({
          classDrop: classDrop,
        })}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center gap-4">
          <BsFillCloudArrowUpFill color="black" size={80} />
          {isDragActive ? (
            <p>Suelta los archivos</p>
          ) : (
            <p>Arrastra tus documentos aqui:</p>
          )}
        </div>
      </div>

      {/* Preview */}
      <section className="mt-10">
        <div className="flex gap-4">
          <button
            type="button"
            onClick={removeAll}
            className="mt-1 text-[10px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors"
          >
            Remove all files
          </button>
          <button
            type="submit"
            id="send"
            className="ml-auto mt-1 text-[10px] uppercase tracking-wider font-bold text-neutral-500 border border-purple-400 rounded-md px-3 hover:bg-purple-400 hover:text-white transition-colors"
          >
            Subir
          </button>
        </div>

        {/* Accepted files */}
        <h3 className="title text-base font-semibold text-neutral-600 mt-5 border-b pb-3">
          Vista Previa
        </h3>
        <ul className="mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-10">
          {files.map((file) => (
            <li key={file.name} className="relative h-32 rounded-md shadow-lg">
              <iframe
                src={file.preview}
                alt={file.name}
                width={100}
                height={100}
                onLoad={() => {
                  URL.revokeObjectURL(file.preview);
                }}
                className="h-full w-full object-contain rounded-md"
                title="file"
              ></iframe>
              <button
                type="button"
                className="w-7 h-7 border border-secondary-400 bg-secondary-400 rounded-full flex justify-center items-center absolute -top-3 -right-3 hover:bg-white transition-colors"
                onClick={() => removeFile(file.name)}
              >
                <FaRegCircleXmark className="w-5 h-5 fill-white hover:fill-secondary-400 transition-colors" />
              </button>
              <p className="mt-2 text-neutral-500 text-[12px] font-medium">
                {file.name}
              </p>
            </li>
          ))}
        </ul>

        {/* Rejected Files */}
        <h3 className="title text-base font-semibold text-neutral-600 mt-24 border-b pb-3">
          Archivos denegados
        </h3>
        <ul className="mt-6 flex flex-col">
          {rejected.map(({ file, errors }) => (
            <li key={file.name} className="flex items-start justify-between">
              <div>
                <p className="mt-2 text-neutral-500 text-sm font-medium">
                  {file.name}
                </p>
                <ul className="text-[12px] text-red-400">
                  {errors.map((error) => (
                    <li key={error.code}>{error.message}</li>
                  ))}
                </ul>
              </div>
              <button
                type="button"
                className="mt-1 py-1 text-[12px] uppercase tracking-wider font-bold text-neutral-500 border border-secondary-400 rounded-md px-3 hover:bg-secondary-400 hover:text-white transition-colors"
                onClick={() => removeRejected(file.name)}
              >
                remove
              </button>
            </li>
          ))}
        </ul>
      </section>

      <iframe id="contenido" title="cont"></iframe>
    </form>
  );
};

export default Dropzone;
