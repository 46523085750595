import React, { useState, useEffect } from "react";
import axios from "axios";
import Menu from "../components/MenuT";
import TECN from "../Resources/TECNpng.png";
import UserId from "../components/logt";
import config from "../config/env";

export default function BoletaT() {
  let name = localStorage.getItem("name");
  let apep = localStorage.getItem("apep");

  const [boleta, setBoleta] = useState([]);
  const [filteredBoleta, setFilteredBoleta] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const BoletaStatus = async () => {
    const response = await axios.get(
      `${
        config.BASE_URL
      }/API_DualSystem/boletaAlumnos.php?id=${localStorage.getItem("did")}`
    );
    setBoleta(response.data);
    setFilteredBoleta(response.data);
  };

  useEffect(() => {
    BoletaStatus();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchTerm, filterBy, filterValue]);

  const filterData = () => {
    let filteredData = [...boleta];

    // Filtrar por término de búsqueda
    if (searchTerm.trim() !== "") {
      filteredData = filteredData.filter((db) =>
        `${db.name} ${db.last_f} ${db.last_m}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }

    // Filtrar por carrera
    if (filterBy === "carrera" && filterValue !== "") {
      filteredData = filteredData.filter(
        (db) => db.id_carrera.toString() === filterValue
      );
    }

    // Filtrar por semestre
    if (filterBy === "semestre" && filterValue !== "") {
      filteredData = filteredData.filter(
        (db) => db.id_semestre.toString() === filterValue
      );
    }

    setFilteredBoleta(filteredData);
  };

  const updateCalificacion = async (id, parcial, value) => {
    try {
      await axios.post(
        `${config.BASE_URL}/API_DualSystem/update_calificacion.php?id=${id}`,
        `parcial=${parcial}&calificacion=${value}`,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );

      setBoleta((prevBoleta) =>
        prevBoleta.map((item) =>
          item.id_student === id ? { ...item, [parcial]: value } : item
        )
      );
    } catch (error) {
      console.error("Error updating grade:", error);
    }
  };

  return (
    <>
      <div className="w-full flex space-x-[75%] items-center">
        {/* Header */}
        <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
          <div className="ml-6 flex items-center">
            <img src={TECN} alt="logotec" className="w-16" />
            <p className="text-xl font-bold ml-2">TUTOR → Bienvenido!</p>
          </div>
        </div>
      </div>

      <Menu />
      {boleta.length === 0 ? (
        <div className="grid place-items-center">
          <h1>No tiene Alumnos Asignados</h1>
        </div>
      ) : (
        <div className="container px-0 place-content-center">
          <div className="grid place-items-center">
            <div className="mt-8 w-5/6 p-5 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
              <p className="title text-base font-bold text-center text-blue-900 m-3">
                CALIFICACIONES
              </p>
              <p className="title text-base font-semibold text-gray-800">
                Datos del Alumno y boleta:
              </p>
              <hr className="bottom-0 w-full h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />

              {/* Filtro */}
              <div className="flex items-center justify-center mt-4 mb-4">
                <input
                  type="text"
                  placeholder="Buscar alumno"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="h-10 px-4 mr-2 bg-gray-200 rounded-lg"
                />
                <select
                  value={filterBy}
                  onChange={(e) => setFilterBy(e.target.value)}
                  className="h-10 px-4 bg-gray-200 rounded-lg"
                >
                  <option value="">Filtrar por</option>
                  <option value="carrera">Carrera</option>
                  <option value="semestre">Semestre</option>
                </select>
                {filterBy && (
                  <select
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                    className="h-10 px-4 bg-gray-200 rounded-lg ml-2"
                  >
                    <option value="">
                      Seleccionar{" "}
                      {filterBy === "carrera" ? "carrera" : "semestre"}
                    </option>
                    {filterBy === "carrera" ? (
                      <>
                        <option value="111">
                          Ing. Sistemas Computacionales
                        </option>
                        <option value="222">Ing. Industrial</option>
                        <option value="333">Ing. Gestión Empresarial</option>
                        <option value="444">Ing. Nanotecnología</option>
                        <option value="555">Ing. Mecatrónica</option>
                        <option value="666">Ing. Electromecánica</option>
                        <option value="777">Ing. Electrónica</option>
                        <option value="888">Ing. Geociencias</option>
                        <option value="999">Lic. Contabilidad</option>
                      </>
                    ) : (
                      <>
                        <option value="1">Primero</option>
                        <option value="2">Segundo</option>
                        <option value="3">Tercero</option>
                        <option value="4">Cuarto</option>
                        <option value="5">Quinto</option>
                        <option value="6">Sexto</option>
                        <option value="7">Séptimo</option>
                        <option value="8">Octavo</option>
                        <option value="9">Noveno</option>
                        <option value="10">Décimo</option>
                        <option value="11">Onceavo</option>
                        <option value="12">Doceavo</option>
                        <option value="0">No cursando</option>
                      </>
                    )}
                  </select>
                )}
                <button
                  onClick={() => {
                    setSearchTerm("");
                    setFilterBy("");
                    setFilterValue("");
                  }}
                  className="h-10 px-4 ml-2 bg-red-500 text-white rounded-lg"
                >
                  Limpiar Filtros
                </button>
              </div>

              {/* Tabla de datos */}
              <table className="w-auto p-2 text-xs text-left text-gray-500 dark:text-blue-950">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Alumno
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No. Control
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Carrera
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Semestre
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Parcial 1
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Parcial 2
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Parcial 3
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Actualizar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBoleta.map((db, index) => (
                    <tr
                      key={db.id_student}
                      className={`${
                        index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                      } border-b`}
                    >
                      <td className="px-6 py-4">
                        {db.name} {db.last_f} {db.last_m}
                      </td>
                      <td className="px-6 py-4">{db.N_control}</td>
                      <td className="px-6 py-4">
                        {db.id_carrera === 111
                          ? "Ing. Sistemas Computacionales"
                          : db.id_carrera === 222
                          ? "Ing. Industrial"
                          : "Otra Carrera"}
                      </td>
                      <td className="px-6 py-4">
                        {db.id_semestre >= 1 && db.id_semestre <= 12
                          ? `${db.id_semestre}°`
                          : "No cursando"}
                      </td>
                      <td className="px-6 py-4">
                        <input
                          className="text-center w-10"
                          name={`p1_${db.id_student}`}
                          type="text"
                          value={db.parcial_1}
                          onChange={(e) =>
                            updateCalificacion(
                              db.id_student,
                              "parcial_1",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          className="text-center w-10"
                          name={`p2_${db.id_student}`}
                          type="text"
                          value={db.parcial_2}
                          onChange={(e) =>
                            updateCalificacion(
                              db.id_student,
                              "parcial_2",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <input
                          className="text-center w-10"
                          name={`p3_${db.id_student}`}
                          type="text"
                          value={db.parcial_3}
                          onChange={(e) =>
                            updateCalificacion(
                              db.id_student,
                              "parcial_3",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-4">
                        <button
                          className="text-white bg-blue-700 hover-bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark-bg-blue-600 dark-hover-bg-blue-700 focus-outline-none dark-focus-ring-blue-800 float-right"
                          type="button"
                          onClick={() =>
                            updateCalificacion(
                              db.id_student,
                              "parcial_1",
                              db.parcial_1
                            )
                          }
                        >
                          Actualizar
                        </button>
                      </td>
                      <input name="idU" type="hidden" value={db.id_student} />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
