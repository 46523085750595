import React, { useState, useEffect } from "react";
import axios from "axios";
import { BsFileEarmarkArrowDownFill } from "react-icons/bs";
import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuE";
import config from "../config/env";

const EmpresaC = () => {
  const [user, setUser] = useState([]);

  const [userInteresado, setUserInteresado] = useState([]);
  const [userData, setUserData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterValue, setFilterValue] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}/API_DualSystem/obcv.php`
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const userResponse = await axios.get(
        `${config.BASE_URL}/API_DualSystem/userE.php?id=${localStorage.getItem(
          "Eid"
        )}`
      );
      setUserData(userResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}/API_DualSystem/interesados.php`
        );
        setUserInteresado(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setFilterValue("");
    setSearchTerm("");
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
    setSearchTerm("");
  };

  const handleClearFilters = () => {
    setFilterBy("");
    setFilterValue("");
    setSearchTerm("");
  };

  const filteredUsers = user.filter((test) => {
    if (filterBy === "carrera") {
      return test.id_carrera.toString() === filterValue;
    } else if (filterBy === "semestre") {
      return test.id_semestre.toString() === filterValue;
    } else if (filterBy === "cv") {
      return test.key_f === 1;
    } else if (filterBy === "certificado") {
      // Agregamos esta condición para filtrar por certificado
      return test.certificate === 5; // Suponiendo que 5 es el valor que indica que hay certificado
    } else {
      return `${test.name} ${test.last_f} ${test.last_m}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    }
  });

  // Filtrar usuarios únicos
  const uniqueUsers = Array.from(
    new Set(filteredUsers.map((user) => user.ID_student))
  ).map((ID_student) => {
    return filteredUsers.find((user) => user.ID_student === ID_student);
  });

  return (
    <div className="mx-auto max-w">
      <div className="fixed w-full flex space-x-[75%] items-center">
        {/* Header */}
        <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
          <div className="ml-6 flex items-center">
            <img src={TECN} alt="logotec" className="w-16" />
            <p className="text-xl font-bold ml-2">EMPRESA → Bienvenido!</p>
          </div>
        </div>
      </div>

      <Menu />
      <div className="ml-28 mb-10 flex">
        <div className="px-0 items flex-center">
          <p className="mt-28 text-5xl font-bold text-[#233255]">Empresa</p>
          <div className="flex felx-cols-2 mt-2">
            <div className="h-1 w-36 bg-blue-400"></div>
            <div className="h-1 w-20 bg-orange-500"></div>
          </div>
          <p className="mt-10 text-2xl font-bold text-[#233255]">
            • ¡Encuentra al estudiante ideal!
          </p>
        </div>
      </div>

      <div className="w-2/3 h-auto flex flex-col-3 rounded-xl ml-28 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)] mb-6 p-2">
        <h2 className="text-lg font-bold m-4">Filtrar</h2>
        <div className="mt-4 m-4">
          <input
            type="text"
            placeholder="Buscar por nombre"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div className="mt-4 m-4">
          <select
            value={filterBy}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="">Filtrar por</option>
            <option value="carrera">Carrera</option>
            <option value="semestre">Semestre</option>
            <option value="cv">CV</option>
            <option value="certificado">Certificado</option>
          </select>
        </div>
        {filterBy === "carrera" && (
          <div className="mt-4">
            <select
              value={filterValue}
              onChange={handleFilterValueChange}
              className="w-full px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Seleccionar Carrera</option>
              <option value="111">Ing. Sistemas Computacionales</option>
              <option value="222">Ing. Industrial</option>
              <option value="333">Ing. Gestión Empresarial</option>
              <option value="444">Ing. Nanotecnología</option>
              <option value="555">Ing. Mecatrónica</option>
              <option value="666">Ing. Electromecánica</option>
              <option value="777">Ing. Electrónica</option>
              <option value="888">Ing. Geociencias</option>
              <option value="999">Lic. Contabilidad</option>
              <option value="0">Ingeniero</option>
            </select>
          </div>
        )}
        {filterBy === "semestre" && (
          <div className="mt-4">
            <select
              value={filterValue}
              onChange={handleFilterValueChange}
              className="w-full px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Seleccionar Semestre</option>
              <option value="1">Primero</option>
              <option value="2">Segundo</option>
              <option value="3">Tercero</option>
              <option value="4">Cuarto</option>
              <option value="5">Quinto</option>
              <option value="6">Sexto</option>
              <option value="7">Séptimo</option>
              <option value="8">Octavo</option>
              <option value="9">Noveno</option>
              <option value="10">Décimo</option>
              <option value="11">Onceavo</option>
              <option value="12">Doceavo</option>
              <option value="0">No cursando</option>
            </select>
          </div>
        )}

        <div className="mt-4">
          <button
            onClick={handleClearFilters}
            className="px-2 py-2 bg-red-500 text-white rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300 ml-10"
          >
            Limpiar Filtros
          </button>
        </div>
      </div>

      <div className="w-2/3 h-auto flex flex-col-3 rounded-xl mx-auto ml-28 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
        <table className="w-full p-2 text-sm text-left text-gray-500 dark:text-blue-950">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
            <tr>
              <th scope="col" className="px-6 py-3">
                Estudiante
              </th>
              <th scope="col" className="px-6 py-3">
                Carrera
              </th>
              <th scope="col" className="px-6 py-3">
                Semestre
              </th>
              <th scope="col" className="px-6 py-3">
                CV
              </th>
              <th>Certificados</th>
            </tr>
          </thead>
          <tbody>
            {uniqueUsers.map((test, index) => (
              <tr
                key={test.ID_student}
                className="bg-white border-b dark:bg-gray-200 dark:border-blue-200"
              >
                <td className="px-6 py-4" scope="row">
                  {`${test.name} ${test.last_f} ${test.last_m}`}
                </td>
                <td className="px-6 py-4">
                  {test.id_carrera == 111
                    ? "Ing. Sistemas Computacionales"
                    : test.id_carrera == 222
                    ? "Ing. Industrial"
                    : test.id_carrera == 333
                    ? "Ing. Gestión Empresarial"
                    : test.id_carrera == 444
                    ? "Ing. Nanotecnología"
                    : test.id_carrera == 555
                    ? "Ing. Mecatrónica"
                    : test.id_carrera == 666
                    ? "Ing. Electromecánica"
                    : test.id_carrera == 777
                    ? "Ing. Electrónica"
                    : test.id_carrera == 888
                    ? "Ing. Geociencias"
                    : test.id_carrera == 999
                    ? "Lic. Contabilidad"
                    : "Ingeniero"}
                </td>
                <td className="px-6 py-4">
                  {test.id_semestre == 1
                    ? "primero"
                    : test.id_semestre == 2
                    ? "Segundo"
                    : test.id_semestre == 3
                    ? "Tercero"
                    : test.id_semestre == 4
                    ? "Cuarto"
                    : test.id_semestre == 5
                    ? "Quinto"
                    : test.id_semestre == 6
                    ? "Sexto"
                    : test.id_semestre == 7
                    ? "Séptimo"
                    : test.id_semestre == 8
                    ? "Octavo"
                    : test.id_semestre == 9
                    ? "Noveno"
                    : test.id_semestre == 10
                    ? "Décimo"
                    : test.id_semestre == 11
                    ? "Onceavo"
                    : test.id_semestre == 12
                    ? "Doceavo"
                    : "No cursando"}
                </td>
                <td className="px-6 py-4">
                  {test.key_f === 1 && (
                    <div>
                      <a
                        href={test.ruta}
                        download={`CV-${test.N_control}`}
                        target="_blank"
                      >
                        CV <BsFileEarmarkArrowDownFill />
                      </a>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4">
                  {test.certificate === 5 && (
                    <div>
                      <a
                        href={test.ruta_certificate}
                        download={`Certificado-${test.N_control}`}
                        target="_blank"
                      >
                        Certificado <BsFileEarmarkArrowDownFill />
                      </a>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/**--------------------añadi esto------------------TABLA DE USUARIOS INTERESADOS----------------------------------------------- */}

      <p className="mt-10 ml-28 text-2xl font-bold text-[#233255]">
        Candidatos
      </p>
      <div className="w-2/3 h-auto flex flex-col-3 rounded-xl mx-auto ml-28 mt-10 mb-10 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
        <table className="w-full p-2 text-sm text-left text-gray-500 dark:text-blue-950">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
            <tr>
              <th scope="col" className="px-6 py-3">
                Estudiante
              </th>
              <th scope="col" className="px-6 py-3">
                Carrera
              </th>
              <th scope="col" className="px-6 py-3">
                Semestre
              </th>
              <th scope="col" className="px-6 py-3">
                Solicitar
              </th>
            </tr>
          </thead>
          <tbody>
            {userInteresado.map((test, index) => {
              // Verificar si el estado es igual a 1
              if (test.estatus === "1") {
                return (
                  <tr
                    key={test.user_id}
                    className="bg-white border-b dark:bg-gray-200 dark:border-blue-200"
                  >
                    <td className="px-6 py-4" scope="row">
                      {`${test.name} ${test.last_f} ${test.last_m}`}
                    </td>
                    <td className="px-6 py-4">
                      {test.id_carrera == 111
                        ? "Ing. Sistemas Computacionales"
                        : test.id_carrera == 222
                        ? "Ing. Industrial"
                        : test.id_carrera == 333
                        ? "Ing. Gestión Empresarial"
                        : test.id_carrera == 444
                        ? "Ing. Nanotecnología"
                        : test.id_carrera == 555
                        ? "Ing. Mecatrónica"
                        : test.id_carrera == 666
                        ? "Ing. Electromecánica"
                        : test.id_carrera == 777
                        ? "Ing. Electrónica"
                        : test.id_carrera == 888
                        ? "Ing. Geociencias"
                        : test.id_carrera == 999
                        ? "Lic. Contabilidad"
                        : "Ingeniero"}
                    </td>
                    <td className="px-6 py-4">
                      {test.id_semestre == 1
                        ? "primero"
                        : test.id_semestre == 2
                        ? "Segundo"
                        : test.id_semestre == 3
                        ? "Tercero"
                        : test.id_semestre == 4
                        ? "Cuarto"
                        : test.id_semestre == 5
                        ? "Quinto"
                        : test.id_semestre == 6
                        ? "Sexto"
                        : test.id_semestre == 7
                        ? "Séptimo"
                        : test.id_semestre == 8
                        ? "Octavo"
                        : test.id_semestre == 9
                        ? "Noveno"
                        : test.id_semestre == 10
                        ? "Décimo"
                        : test.id_semestre == 11
                        ? "Onceavo"
                        : test.id_semestre == 12
                        ? "Doceavo"
                        : "No cursando"}
                    </td>
                    <td className="px-6 py-4">
                      <form
                        method="POST"
                        action={`${config.base_url}/API_DualSystem/solicitar.php`}
                      >
                        <input name="id" type="hidden" value={test.user_id} />
                        {userData.map((user, index) => (
                          <input
                            name="empresa"
                            type="hidden"
                            value={user.empresa_a}
                          />
                        ))}
                        <button className="bg-green-500 font-bold">
                          SOLICITAR
                        </button>
                        {console.log(localStorage.getItem("name"))}
                      </form>
                    </td>
                  </tr>
                );
              } else {
                return null; // O simplemente no hacer nada si no cumple la condición
              }
            })}
          </tbody>
        </table>
      </div>
      {/**--------------------------------------TABLA DE USUARIOS INTERESADOS----------------------------------------------- */}
    </div>
  );
};

export default EmpresaC;
