import { useNavigate } from 'react-router-dom';
import ING from '../Resources/test.JPG';
import config from '../config/env'

export default function Registro(){

    // Back to Login Function
    const naviget = useNavigate();
    function logoutSubmit(){
        localStorage.setItem("loginE", false);
        localStorage.setItem("loginStatus", "Sesión Cerrada Correctamente");
        naviget("/");
    }

    // Start HandleSubmit (Confirm button register)
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Envía el formulario al servidor
        const formData = new FormData(event.target);
        const response = await fetch(`${config.BASE_URL}/API_DualSystem/interesado.php`, {
            method: 'POST',
            body: formData
        });
        
        if (response.ok) {
            logoutSubmit();
            alert('Registro completado, recuerda, no puedes iniciar sesion hasta que te validen.');
        } else {
            console.error('Error al enviar el formulario al servidor');
        }
    };

    return(
        
            <div className='bg-cover bg-center h-screen relative' style={{ backgroundImage: `url(${ING})` }}>
                <div className='absolute inset-x-0 mx-auto top-1/4 bg-white bg-opacity-60 p-8 rounded-lg'>
                <div className='text-center text-xl font-bold'>
                    <h2>Bienvenido al registro de interesados en la modalidad DUAL</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='grid grid-cols-3'>
                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Correo</label>
                    <input placeholder='Correo' name="correo" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Contraseña</label>
                    <input placeholder='Contraseña' name="password" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Nombre</label>
                    <input placeholder='Nombre' name="name" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Apellido Paterno</label>
                    <input placeholder='Apellido Paterno' name="last_f" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Apellido Materno</label>
                    <input placeholder='Apellido Materno' name="last_m" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">No. Control</label>
                    <input placeholder='Numero Control' name="N_control" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Curp</label>
                    <input placeholder='Curp' name="curp" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Telefono</label>
                    <input placeholder='Telefono' name="phone" type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Fecha Nacimiento</label>
                    <input  name="fecha" type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <label className="font-bold text-blue-950" htmlFor="">Dirección</label>
                    <input placeholder='Direccion' name="adress" type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                    </div>

                    <div className="m-4">
                    <select name="semestre" id="" className="mb-5 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="#" >SEMESTRE</option>
                        <option value="1" >Primer</option>
                        <option value="2" >Segundo</option>
                        <option value="3" >Tercero</option>
                        <option value="4" >Cuarto</option>
                        <option value="5" >Quinto</option>
                        <option value="6" >Sexto</option>
                        <option value="7" >Septimo</option>
                        <option value="8" >Octavo</option>
                        <option value="9" >Noveno</option>
                        <option value="10" >Decimo</option>
                        <option value="11" >Onceavo</option>
                        <option value="12" >Doceavo</option>
                                        </select> </div>

                    <div className="m-4">
                    <select name="carrera" id="" className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="#" >Carrera</option>
                        <option value="111" >Ing. Sistemas Computacionales</option>
                        <option value="222" >Ing. Industrial</option>
                        <option value="333" >Ing. Gestión Empresarial</option>
                        <option value="444" >Ing. Nanotecnologia</option>
                        <option value="555" >Ing. Mecatronica</option>
                        <option value="666" >Ing. Electromecanica</option>
                        <option value="777" >Ing. Electronica</option>
                        <option value="888" >Ing. Geociencias</option>
                        <option value="999" >Lic. Contabilidad</option>
                                        </select></div>
                    <button type="submit" id='env' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Registrarme</  button>
</div>
                </form>
                </div>
            </div>
        
    )
}