import React, { useEffect, useState } from "react";
import axios from "axios";
import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuT";
import { BiSolidReport } from "react-icons/bi";
import config from "../config/env";

export default function ReportsT() {
  const [userData, setUserData] = useState([]);
  const [userReports, setUserReports] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterValue, setFilterValue] = useState("");

  const fetchData = async () => {
    try {
      const userResponse = await axios.get(
        `${config.BASE_URL}/API_DualSystem/userT.php?id=${localStorage.getItem(
          "did"
        )}`
      );
      setUserData(userResponse.data);

      const idsStudents = userResponse.data.map(
        (student) => student.ID_student
      );
      const usersPromises = idsStudents.map(async (idStudent) => {
        const usResponse = await axios.get(
          `${config.BASE_URL}/API_DualSystem/p1.php?id=${idStudent}`
        );
        return { idStudent, reports: usResponse.data };
      });

      const usersData = await Promise.all(usersPromises);
      const reports = {};
      usersData.forEach((user) => {
        reports[user.idStudent] = user.reports;
      });
      setUserReports(reports);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setSearchTerm("");
  };

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value);
    setSearchTerm("");
  };

  const handleClearFilters = () => {
    setFilterBy("");
    setFilterValue("");
    setSearchTerm("");
  };

  const filteredUserData = userData.filter((student) => {
    const fullName =
      `${student.name} ${student.last_f} ${student.last_m}`.toLowerCase();
    const controlNumber = student.N_control.toString().toLowerCase();
    const matchFullName = fullName.includes(searchTerm);
    const matchControlNumber = controlNumber.includes(searchTerm);
    const matchCarrera = student.id_carrera.toString() === filterValue;
    const matchSemestre = student.id_semestre.toString() === filterValue;

    if (searchTerm) {
      return matchFullName || matchControlNumber;
    } else if (filterBy === "carrera") {
      return matchCarrera;
    } else if (filterBy === "semestre") {
      return matchSemestre;
    } else {
      return true;
    }
  });

  return (
    <div className="mx-auto max-w">
      <div className="w-full h-full flex space-x-[75%] items-center">
        {/* Header */}
        <div className="mb-8 w-full bg-gray-800 text-white py-4 flex items-center justify-between">
          <div className="ml-6 flex items-center">
            <img src={TECN} alt="logotec" className="w-16" />
            <p className="text-xl font-bold ml-2">TUTOR → Bienvenido!</p>
          </div>
        </div>
      </div>

      <Menu />
      <div className="w-5/6 h-full grid mx-auto">
        <div className="flex flex-col-4 mb-5">
          <div className="">
            <input
              type="text"
              placeholder="Buscar estudiante"
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-8 w-50 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
            />
          </div>
          <div className="col-span-3 ml-4 mr-4 h-auto">
            <select
              value={filterBy}
              onChange={handleFilterChange}
              className="h-10 p-2 bg-gray-200 rounded-lg"
            >
              <option value="">Filtrar por</option>
              <option value="carrera">Carrera</option>
              <option value="semestre">Semestre</option>
            </select>
            {filterBy && (
              <select
                value={filterValue}
                onChange={handleFilterValueChange}
                className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
              >
                <option value="">
                  Seleccionar {filterBy === "carrera" ? "Carrera" : "Semestre"}
                </option>
                {/* Opciones de Carrera */}
                {filterBy === "carrera" && (
                  <>
                    <option value="111">Ing. Sistemas Computacionales</option>
                    <option value="222">Ing. Industrial</option>
                    <option value="333">Ing. Gestión Empresarial</option>
                    <option value="444">Ing. Nanotecnología</option>
                    <option value="555">Ing. Mecatrónica</option>
                    <option value="666">Ing. Electromecánica</option>
                    <option value="777">Ing. Electrónica</option>
                    <option value="888">Ing. Geociencias</option>
                    <option value="999">Lic. Contabilidad</option>
                    <option value="0">Ingeniero</option>
                  </>
                )}
                {/* Opciones de Semestre */}
                {filterBy === "semestre" && (
                  <>
                    <option value="1">Primero</option>
                    <option value="2">Segundo</option>
                    <option value="3">Tercero</option>
                    <option value="4">Cuarto</option>
                    <option value="5">Quinto</option>
                    <option value="6">Sexto</option>
                    <option value="7">Séptimo</option>
                    <option value="8">Octavo</option>
                    <option value="9">Noveno</option>
                    <option value="10">Décimo</option>
                    <option value="11">Onceavo</option>
                    <option value="12">Doceavo</option>
                    <option value="0">No cursando</option>
                  </>
                )}
              </select>
            )}
            <button
              onClick={handleClearFilters}
              className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Limpiar Filtros
            </button>
          </div>
        </div>
        <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
            <tr>
              <th scope="col" className="px-6 py-3 w-6">
                Estudiante
              </th>
              <th scope="col" className="px-6 py-3 w-6">
                Reportes Subidos:
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUserData.map((student, index) => (
              <tr
                key={student.ID_student}
                className={
                  index % 2 === 0
                    ? "bg-gray-300 border-b"
                    : "bg-gray-100 border-b"
                }
              >
                <td className="px-6 py-4 w-6">{`${student.name} ${student.last_f} ${student.last_m}`}</td>
                <td className="px-6 py-4 w-6">
                  {userReports[student.ID_student] &&
                    userReports[student.ID_student].map(
                      (report, reportIndex) => (
                        <div key={reportIndex} className="mt-2">
                          <a
                            className="font-bold text-green-600 text-xl"
                            href={report.ruta}
                            target="_blank"
                          >
                            {`Semana ${report.parcial}`}
                            <BiSolidReport color="orange" />
                          </a>
                          {report.status_t === 1 && ( // Mostrar botón "Marcar Revisado"
                            <form
                              method="POST"
                              action={`${config.BASE_URL}/API_DualSystem/StatusRD.php`}
                            >
                              <input
                                name="id"
                                type="hidden"
                                value={report.id_user}
                              />
                              <input
                                name="idfile"
                                type="hidden"
                                value={report.id_file}
                              />
                              <button className="w-auto h-auto bg-blue-500 font-bold">
                                Marcar Revisado
                              </button>
                            </form>
                          )}
                          {report.status_t === 2 && ( // Mostrar botón "REVISADO"
                            <button
                              className="w-auto h-auto bg-green-500 font-bold"
                              disabled // Deshabilitar botón
                              readOnly // Hacer botón de solo lectura
                            >
                              REVISADO
                            </button>
                          )}
                        </div>
                      )
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
