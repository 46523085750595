import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Protected = (props) => {

    const naviget = useNavigate();
    const { Component } = props;


    useEffect(() => {
        let login = localStorage.getItem("login");
        if(!login){
            localStorage.setItem("loginStatus", "Inicie Sesión para continuar");
            naviget("/",{replace: true});
        }
    }, []);
    return(
        <Component/>
    );
}
export default Protected;