import { Link, Outlet } from "react-router-dom";
import Menu from "../components/Menu";



export default function MessagesC(){
    return (
        
        <div className="container">

            <Menu />
            <h1>Messages</h1>
            <p>Pagina Nueva</p>
            <div>
                <Outlet />
            </div>

        </div>
        
        

        
    );
}