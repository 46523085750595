import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
/*From here the router starts*/
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; 
/**we load the jsx views that will be displayed */
import Home from "./routes/Home";
import InstrumentC from './routes/Instrument';
import DocumentsC from './routes/Documents';
import SubjectsC from './routes/Subject';
import LoginC from './routes/login';
import TeachersC from './routes/Teachers'
import SocialC from './routes/Social'
import MessagesC from './routes/Messages'
import AnalyticsC from './routes/Analytics'
import ReportsC from './routes/Reports'
import InfoC from './routes/Info'
import ConfigC from './routes/Config';
import Error from './routes/Error';
import Protected from './routes/protected';
import DropS from './components/send/dropSeguro';
import DropCV from './components/send/dropCV';
import DropCURP from './components/send/dropCurp';
import DropKardex from './components/send/dropKardex';
import AdminC from './routes/admin';
import EmpresaC from './routes/empresa';
import DocenteC from './routes/teacher';
import DocumentsT from './routes/DocumentsT';
import BoletaT from './routes/BoletaT';
import BoletaE from './routes/BoletaE';
import DocumentsE from './routes/DocumentsE';
import Add from './routes/add_users';
import ReportsS from './routes/rep_s';
import DropP1 from './components/send/p1Drop';
import DropP2 from './components/send/p2Drop';
import DropP3 from './components/send/p3Drop';
import ReportsT from './routes/reportsT';
import ReportsE from './routes/reportsE';
import PsicoC from './routes/psicosocial';
import DropCertificate from './components/send/dropCertificate';
import Registro from './routes/register';
/*import AddUser from './routes/agregarUser';*/




/**router builder */
const router = createBrowserRouter([
  /**create a new fixed path if you want to add a new file */
  //Login
  {

    path: '/admin',
    element: <AdminC />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/add_user',
    element: <Add />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/teacher',
    element: <DocenteC />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/DocumentsT',
    element: <DocumentsT />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/BoletaT',
    element: <BoletaT />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/ReportesT',
    element: <ReportsT />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/empresa',
    element: <EmpresaC />,
    errorElement: <Error />,
    
  
  },
  {

    path: '/DocumentsE',
    element: <DocumentsE/>,
    errorElement: <Error />
  
  },
  {

    path: '/BoletaE',
    element: <BoletaE/>,
    errorElement: <Error />
  
  },
  {

    path: '/ReportesE',
    element: <ReportsE />,
    errorElement: <Error />,
    
  
  },
  {

  path: '/',
  element: <LoginC />,
  errorElement: <Error />,
  },
  
  {
  path: '/Register',
  element: <Registro />,
  errorElement: <Error/>,
  },
//DASHBOARD
{

  path: '/home',
  element: <Protected Component={Home} />,
  errorElement: <Error />,
  

},
//STUDENTS
{

  path: '/Instrument',
  element: <Protected Component={InstrumentC} />,
  errorElement: <Error />,
  children:[{
    path: "mbi",
    element: <TeachersC/>,
},
{
  path: "riesgops",
  element: <PsicoC/>,
}
,],
  
  
},
//DOCUMENTS
{

  path: '/Documents',
  element: <Protected Component={DocumentsC} />,
  errorElement: <Error />,
  children:[{
    path: "seguro",
    element: <DropS/>,
},
{
  path: "cv",
  element: <DropCV/>,
},
{
  path: "curp",
  element: <DropCURP/>,
},
{
  path: "kardex",
  element: <DropKardex/>,
},
{
  path: "Certificate",
  element: <DropCertificate/>,
}],

},
//SUBJECTS
{

  path: '/Boleta',
  element: <Protected Component={ReportsC} />,
  errorElement: <Error />

},







//TEACHERS
{

  path: '/Teachers',
  element: <Protected Component={TeachersC} />,
  errorElement: <Error />

},
//Social
{

  path: '/Social',
  element: <Protected Component={SocialC} />,
  errorElement: <Error />

},
//MESSAGES
{

  path: '/Messages',
  element: <Protected Component={MessagesC} />,
  errorElement: <Error />

},
{

  path: '/Reportes',
  element: <Protected Component={ReportsS} />,
  errorElement: <Error />,
  children:[
    {
    
  path: "p1",
  element: <DropP1/>
},
{
  path: "parcial2",
  element: <DropP2/>
},
{
  path: "parcial3",
  element: <DropP3/>
}],

},
//ANALYTICS
{

  path: '/Analytics',
  element: <Protected Component={AnalyticsC} />,
  errorElement: <Error />

},

//INFO
{

  path: '/Info',
  element: <Protected Component={InfoC} />,
  errorElement: <Error />

},
//CONFIG
{

  path: '/Config',
  element: <Protected Component={ConfigC} />,
  errorElement: <Error />

},
//LOGIN-------------------------
/*
{

  path: '/login',
  element: <LoginC />,
  errorElement: <Error />

}*/
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
