import { useEffect, useState } from "react";
import axios from "axios";
import config from "../config/env";

export default function Reticula(){

    const [alumno, setAlumno]= useState([]);

    const AlumnoStatus = async()=>{
        await axios.get(`${config.BASE_URL}/API_DualSystem/user.php?id=${localStorage.getItem("id")}`)
        .then(us => {
            setAlumno(us.data);
        })
    }
    useEffect(()=>{
        AlumnoStatus();
    }, [])





    return (
        <div className="w-1/2 mt-6">


<table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
  <tr>
    <th scope="col" className="px-6 py-3">Carrera</th>
    <th scope="col" className="px-6 py-3">Semestre</th>
   
    
    

    
  
  </tr>
</thead>
<tbody>
{(alumno.map((us, index)=>(
<tr key={us.ID_student} className={`${index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-100'} border-b`}>
                 
                
                  <td className="px-6 py-4">
                    {us.id_carrera == 111
                      ? 'Ing. Sistemas Computacionales'
                      : us.id_carrera == 222
                      ? 'Ing. Industrial'
                      : us.id_carrera == 333
                      ? 'Ing. Gestión Empresarial'
                      : us.id_carrera == 444
                      ? 'Ing. Nanotecnología'
                      : us.id_carrera == 555
                      ? 'Ing. Mecatrónica'
                      : us.id_carrera == 666
                      ? 'Ing. Electromecánica'
                      : us.id_carrera == 777
                      ? 'Ing. Electrónica'
                      : us.id_carrera == 888
                      ? 'Ing. Geociencias'
                      : us.id_carrera == 999
                      ? 'Lic. Contabilidad'
                      : 'Ingeniero'}
                  </td>
                  <td className="px-6 py-4">
                    {us.id_semestre == 1
                      ? 'primero'
                      : us.id_semestre == 2
                      ? 'Segundo'
                      : us.id_semestre == 3
                      ? 'Tercero'
                      : us.id_semestre == 4
                      ? 'Cuarto'
                      : us.id_semestre == 5
                      ? 'Quinto'
                      : us.id_semestre == 6
                      ? 'Sexto'
                      : us.id_semestre == 7
                      ? 'Séptimo'
                      : us.id_semestre == 8
                      ? 'Octavo'
                      : us.id_semestre == 9
                      ? 'Noveno'
                      : us.id_semestre == 10
                      ? 'Décimo'
                      : us.id_semestre == 11
                      ? 'Onceavo'
                      : us.id_semestre == 12
                      ? 'Doceavo'
                      : 'No cursando'}
                  </td>
                  
                </tr> ) ))}

  
                    </tbody>
                  </table>






        <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
            <tr>
                <th scope="col" className="px-6 py-3">Materias</th>
                
            
                
                

                
            
            </tr>
            </thead>


           
            <tbody>
  {alumno.map((us, index) => (
    <tr key={us.ID_student} className={`${index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-100'} border-b`}>
      <td className="px-6 py-4">
        {us.id_carrera === 111 && us.id_semestre === 1
          ?<span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 2
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 3
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 4
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 5
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 6
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 7
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 8
          ? <span>  </span>
          : us.id_carrera === 111 && us.id_semestre === 9
          ? <span>  </span>
          
          // ---------------------INDUSTRIAL----------------------------------------------------------------
          : us.id_carrera === 222 && us.id_semestre === 1
          ? <span>Fundamentos de Investigación ACC-0906 <br /> Quimica INC-1025 <br /> Calculo Diferencial <br /> Taller de Herramientas Intelectuales INH-1029 <br /> Taller de Etica ACA-0907 <br /> Dibujo Industrial INN-1008 <br /> Taller de Liderazgo INC-1030  </span>
          : us.id_carrera === 222 && us.id_semestre === 2
          ? <span>Electricidad y Electronica Industrial INC-1009 <br /> Propiedad de los Materiales INC-1024 <br /> Calculo Integral ACF-0902 <br /> Probabilidad y Estadistica AEC-1053 <br /> Análisis de la Realidad Nacional INQ-1006 <br /> Metrologia y Normalización AEC-1048 </span>
          : us.id_carrera === 222 && us.id_semestre === 3
          ? <span>Higiene y Seguridad Industrial INF-1016 <br /> Procesos de Fabricación INC-1023 <br /> Álgebra Lineal ACF-0903 <br /> Calculo Vectorial ACF-0904 <br /> Estadistica Inferencial I AEF-1024 <br /> Estudio del Trabajo I INJ-1011</span>
          : us.id_carrera === 222 && us.id_semestre === 4
          ? <span>Fisica INC-1013 <br /> gestión de costos INC-1014 <br /> Algoritmos y lenguajes de programación INC-1005 <br /> Investigación de Operaciones I INC-1018 <br /> Estadistica Inferencial II AEF-1025 <br /> Estudio del Trabajo II INJ-1011 <br /> Desarrollo Sustentable ACD-0908</span>
          : us.id_carrera === 222 && us.id_semestre === 5
          ? <span>Taller de Investigación II ACA-0910 <br /> Ingenieria Economica AEC-1037 <br /> Administración de las operaciones II INC-1019 <br /> Simulación INC-1004 <br /> Ingenieria de Sistemas INR-1017 <br /> Mercadotecnia AED-1044 <br /> Gestión de los sistemas de Calidad</span>
          : us.id_carrera === 222 && us.id_semestre === 6
          ? <span>Formulación y Evaluación de proyectos AED-1030 <br /> Planeación Financiera INC-1021 <br /> Planeación y diseño de Instalaciones INC-1022 <br /> Sistemas de Manufactura <br /> Logística y Cadena de Suministro INH-1020 <br /> Relaciones Industriales</span>
          : us.id_carrera === 222 && us.id_semestre === 7
          ? <span>Formulación y Evaluacion de Proyctos AED-1030 <br /> Planeación Financiera INC-1021 <br /> Planeacion y Diseño de Instalaciones INC-1022 <br /> Sistemas de Manufactura INF-1018 <br /> Logistica y Cadena de Suministro INH-1020 <br /> Relaciones Industriales INC-1026</span>
          : us.id_carrera === 222 && us.id_semestre === 8
          ? <span>Especialidad y Servicio Social</span>
          : us.id_carrera === 222 && us.id_semestre === 9
          ? <span>Residencia Profesional</span>
          // ---------------------GESTION EMPRESARIAL----------------------------------------------------------------
          : us.id_carrera === 333 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 333 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------NANOTECNOLOGIA----------------------------------------------------------------
          : us.id_carrera === 444 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 444 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------MECATRONICA----------------------------------------------------------------
          : us.id_carrera === 555 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 555 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------ELECTROMECANICA----------------------------------------------------------------
          : us.id_carrera === 666 && us.id_semestre === 1
          ? <span> Quimica <br /> Calculo Diferencial <br /> Taller de Ética <br /> Desarrollo Sustentable <br /> Introducción a la programacion <br /> Fundamentos de Inv. </span>
          : us.id_carrera === 666 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 666 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------ELECTRONICA----------------------------------------------------------------
          : us.id_carrera === 777 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 777 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------GEOCIENCIAS----------------------------------------------------------------
          : us.id_carrera === 888 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 888 && us.id_semestre === 9
          ? <span>   </span>
          // ---------------------CONTABILIDAD----------------------------------------------------------------
          : us.id_carrera === 999 && us.id_semestre === 1
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 2
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 3
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 4
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 5
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 6
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 7
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 8
          ? <span>   </span>
          : us.id_carrera === 999 && us.id_semestre === 9
          ? <span>   </span>
          // ... más condiciones para Ing. Industrial en diferentes semestres
          : 'Otro contenido predeterminado'}
      </td>
      {/* Agrega más columnas aquí si es necesario */}
    </tr>
  ))}
</tbody>

                  </table>






        </div>
    );
}