import TECN from "../Resources/TECNpng.png";
import Menu from "../components/Menu";
import UserId from "../components/userdiv";

import Dropzone from "../components/dropzone";
import FilesUser from "../components/files_table_student";

import CurpC from "../components/curp";
import CvC from "../components/cv";
import KardexC from "../components/kardex";
import SeguroC from "../components/seguro";
import { Outlet } from "react-router-dom";
import TableUser from "../components/table_user";
import Certificate from "../components/certificado";

let name = localStorage.getItem("name");
let apep = localStorage.getItem("apep");

function DocumentsC() {
  return (

    
    <div className="mx-auto max-w">
      
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">ESTUDIANTE → Bienvenido.</p>
          <p className="text-xl font-bold ml-2">
            {name} {apep}
          </p>
        </div>
      </div>

      <Menu />

      <div className="w-5/6 h-full grid mx-auto">
        {/**CONTENT DIV----------------------- */}

        <div className="w-full mt-4 p-5 h-auto flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
          <div className="grid grid-cols-4 gap-2">
            <p className="title text-2xl font-bold text-left col-span-4">
              Documentacion
            </p>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 col-span-4" />
            <CurpC />
            <CvC />
            <SeguroC />
            <KardexC />
            <Certificate />
          </div>
          <br />

          <TableUser />
        </div>

        <Outlet />
      </div>
      {/**CONTENT DIV------------------END----- */}
    </div>
  );
}

export default DocumentsC;
