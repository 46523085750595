import axios from "axios";
import { useEffect, useState } from "react";
import { BsFillCircleFill } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa6";
import { Link } from "react-router-dom";
import config from "../config/env";

export default function FilesUser() {
  const [file, setFile] = useState([]);

  const filesReciv = async () => {
    await axios
      .get(`${config.BASE_URL}/API_DualSystem/fileSend.php?id=${localStorage.getItem("did")}`)
      .then((db) => {
        setFile(db.data);
      });
  };

  useEffect(() => {
    filesReciv();
  }, []);

  return (
    <div>
      {file.length === 0 ? (
        <Link to={'/Documents'}>
          <div className="grid place-items-center">
            <div className="w-24 h-24 border-2 border-solid border-blue-950 text-center flex items-center justify-center rounded-full">
              <div className="w-20 h-20 border-2 border-solid border-orange-600 text-center flex items-center justify-center rounded-full shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                <FaUserGraduate size={'3rem'} color={'#19284C'} />
              </div>
            </div>
            <p className="font-bold text-gray-900 text-base text-center">Completa tu Perfil</p>
            <p className="font-semibold text-left text-blue-950 text-sm mt-1">Curp</p>
            <hr />
            <p className="font-semibold text-left text-blue-950 text-sm mt-1">CV</p>
            <hr />
            <p className="font-semibold text-left text-blue-950 text-sm mt-1">Seguro</p>
            <hr />
            <p className="font-semibold text-left text-blue-950 text-sm mt-1">Kardex</p>
          </div>
        </Link>
      ) : (
        <div className="grid place-items-center">
          <div className="w-24 h-24 border-2 border-solid border-green-400 text-center flex items-center justify-center rounded-full">
            <div className="w-20 h-20 border-2 border-solid border-blue-950 text-center flex items-center justify-center rounded-full shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
              <FaUserGraduate size={'3rem'} color={'#19284C'} />
            </div>
          </div>
          <p className="font-bold text-gray-900 text-base text-center">Completa tu Perfil</p>
          <p className="font-semibold text-left text-blue-950 text-sm mt-1">Curp {file[0].key_f == 2 ? 'Verificado' : 'No Verificado'}</p>
          <hr />
          <p className="font-semibold text-left text-blue-950 text-sm mt-1">CV {file[0].key_f == 1 ? 'Verificado' : 'No Verificado'}</p>
          <hr />
          <p className="font-semibold text-left text-blue-950 text-sm mt-1">Seguro {file[0].key_f == 4 ? 'Verificado' : 'No Verificado'}</p>
          <hr />
          <p className="font-semibold text-left text-blue-950 text-sm mt-1">Kardex {file[0].key_f == 3 ? 'Verificado' : 'No Verificado'}</p>
        </div>
      )}
    </div>
  );
}
