import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config/env";

export default function TableUser() {
  const [user, setUser] = useState([]);

  const UserStatus = async () => {
    await axios
      .get(`${config.BASE_URL}/API_DualSystem/user.php?id=${localStorage.getItem("id")}`)
      .then((us) => {
        setUser(us.data);
      });
  }

  useEffect(() => {
    UserStatus();
  }, []);

  return (
    <div>
      <form>
        {user.map((students) => (
          <div key={students.ID_student} className="my-4">
            <div>
              <p className="title text-base text-blue-500 font-bold mt-5">PERSONALES</p>
              <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
              <div className="space-y-2 grid grid-cols-3">
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Nombre Completo
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${(students.name.length + students.last_f.length + students.last_m.length + 1) * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={`${students.name} ${students.last_f} ${students.last_m}`}
                  />
                </div>

                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Fecha de Nacimiento
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.fecha.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.fecha}
                  />
                </div>

                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    No° Control
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.N_control.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.N_control}
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Curp
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.curp.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.curp}
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Teléfono
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.phone.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.phone}
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Carrera
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '270px' }} // Establece un ancho mínimo (ajusta según tus necesidades)
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      students.id_carrera == 111
                        ? 'Ing. Sistemas Computacionales'
                        : students.id_carrera == 222
                        ? 'Ing. Industrial'
                        : students.id_carrera == 333
                        ? 'Ing. Gestión Empresarial'
                        : students.id_carrera == 444
                        ? 'Ing. Nanotecnología'
                        : students.id_carrera == 555
                        ? 'Ing. Mecatrónica'
                        : students.id_carrera == 666
                        ? 'Ing. Electromecánica'
                        : students.id_carrera == 777
                        ? 'Ing. Electrónica'
                        : students.id_carrera == 888
                        ? 'Ing. Geociencias'
                        : students.id_carrera == 999
                        ? 'Lic. Contabilidad'
                        : 'Ingeniero'
                    }
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Semestre
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '100px' }} // Establece un ancho mínimo (ajusta según tus necesidades)
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      students.id_semestre == 1
                        ? 'primero'
                        : students.id_semestre == 2
                        ? 'Segundo'
                        : students.id_semestre == 3
                        ? 'Tercero'
                        : students.id_semestre == 4
                        ? 'Cuarto'
                        : students.id_semestre == 5
                        ? 'Quinto'
                        : students.id_semestre == 6
                        ? 'Sexto'
                        : students.id_semestre == 7
                        ? 'Séptimo'
                        : students.id_semestre == 8
                        ? 'Octavo'
                        : students.id_semestre == 9
                        ? 'Noveno'
                        : students.id_semestre == 10
                        ? 'Décimo'
                        : students.id_semestre == 11
                        ? 'Onceavo'
                        : students.id_semestre == 12
                        ? 'Doceavo'
                        : 'No cursando'
                    }
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Domicilio
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.adress.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.adress}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="mt-7 title text-base text-blue-500 font-bold">EMPRESA</p>
              <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
              <div className="space-y-2 grid grid-cols-3">
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Asesor
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${(students.name_a.length + students.apep_a.length + students.apem_a.length + 1) * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={`${students.name_a} ${students.apep_a} ${students.apem_a}`}
                  />
                </div>
                
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Nombre
                  </label>
                  <input
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.empresa_a}
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Teléfono
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.phone_a.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.phone_a}
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Area
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '270px' }} // Establece un ancho mínimo (ajusta según tus necesidades)
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      students.id_carrera_a == 111
                        ? 'Ing. Sistemas Computacionales'
                        : students.id_carrera_a == 222
                        ? 'Ing. Industrial'
                        : students.id_carrera_a == 333
                        ? 'Ing. Gestión Empresarial'
                        : students.id_carrera_a == 444
                        ? 'Ing. Nanotecnología'
                        : students.id_carrera_a == 555
                        ? 'Ing. Mecatrónica'
                        : students.id_carrera_a == 666
                        ? 'Ing. Electromecánica'
                        : students.id_carrera_a == 777
                        ? 'Ing. Electrónica'
                        : students.id_carrera_a == 888
                        ? 'Ing. Geociencias'
                        : students.id_carrera_a == 999
                        ? 'Lic. Contabilidad'
                        : 'Ingeniero'
                    }
                  />
                </div>
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Ubicación
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.adress_a.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.adress_a}
                  />
                </div>
              </div>
            </div>
            
            <div>
              <p className="mt-7 title text-base text-blue-500 font-bold">ACADEMICOS</p>
              <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
              <div className="space-y-2 grid grid-cols-3">
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Tutor Académico
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${(students.name_d.length + students.apep_d.length + students.apem_d.length + 1) * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={`${students.name_d} ${students.apep_d} ${students.apem_d}`}
                  />
                </div>
                
                
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Carrera
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '270px' }} // Establece un ancho mínimo (ajusta según tus necesidades)
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      students.id_carrera_d == 111
                        ? 'Ing. Sistemas Computacionales'
                        : students.id_carrera_d == 222
                        ? 'Ing. Industrial'
                        : students.id_carrera_d == 333
                        ? 'Ing. Gestión Empresarial'
                        : students.id_carrera_d == 444
                        ? 'Ing. Nanotecnología'
                        : students.id_carrera_d == 555
                        ? 'Ing. Mecatrónica'
                        : students.id_carrera_d == 666
                        ? 'Ing. Electromecánica'
                        : students.id_carrera_d == 777
                        ? 'Ing. Electrónica'
                        : students.id_carrera_d == 888
                        ? 'Ing. Geociencias'
                        : students.id_carrera_d == 999
                        ? 'Lic. Contabilidad'
                        : 'Ingeniero'
                    }
                  />
                </div>

                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    No° Control
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: `${students.n_control_d.length * 9}px` }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={students.n_control_d}
                  />
                </div>
                
              </div>
                
              </div>
            </div>
        


        ))}
        <br />
       {/* <button
          className="text-white bg-blue-700 hover-bg-blue-800 focus-ring-4 focus-ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark-bg-blue-600 dark-hover-bg-blue-700 focus-outline-none dark-focus-ring-blue-800 float-right"
          type="submit"
        >
          Actualizar
        </button> */}
                </form> 
    </div>
  );
}

