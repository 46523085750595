import TECN from "../Resources/TECNpng.png";
import Menu from "../components/MenuT";
import UserId from "../components/logt";
import { Outlet } from "react-router-dom";
import TableUserT from "../components/table_userT";

function DocumentsT() {
  return (
    <div className="container px-0">
      <div className="w-full h-full flex space-x-[75%] items-center">
        <img src={TECN} alt="logotec" className="w-14 ml-6" />
        <UserId />
      </div>
      <Menu />

      <div className="w-5/6 h-full grid mx-auto">
        {/**CONTENT DIV----------------------- */}

        <div className="w-full p-5 h-auto mb-7 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
          <div className="grid grid-cols-4 gap-2">
            <p className="title text-2xl font-bold text-left col-span-4">
              Alumnos Vinculados - Información personal
            </p>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 col-span-4" />
          </div>
          <br />
          <TableUserT />
        </div>

        <Outlet />
      </div>
      {/**CONTENT DIV------------------END----- */}
    </div>
  );
}

export default DocumentsT;
