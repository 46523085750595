import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import UserId from "../components/logout";
import TECN from "../Resources/TECNpng.png";
import config from "../config/env";

export default function AdminC() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTermUsers, setSearchTermUsers] = useState("");
  const [filterByUsers, setFilterByUsers] = useState("");
  const [filterValueUsers, setFilterValueUsers] = useState("");

  const loadUsers = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/AllUsers.php`
    );
    setUsers(response.data);
    setFilteredUsers(response.data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const filterData = (data, searchTerm, filterBy, filterValue) => {
    let filteredData = [...data];

    if (searchTerm.trim() !== "") {
      filteredData = filteredData.filter(
        (item) =>
          `${item.name} ${item.last_f} ${item.last_m}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          (item.N_control &&
            item.N_control.toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
    }

    if (filterBy !== "" && filterValue !== "") {
      filteredData = filteredData.filter(
        (item) => item[filterBy].toString() === filterValue
      );
    }

    return filteredData;
  };

  const handleSearchChangeUsers = (e) => {
    setSearchTermUsers(e.target.value);
  };

  const handleFilterChangeUsers = (e) => {
    setFilterByUsers(e.target.value);
    setFilterValueUsers("");
  };

  const handleValueChangeUsers = (e) => {
    setFilterValueUsers(e.target.value);
  };

  const handleClearFiltersUsers = () => {
    setSearchTermUsers("");
    setFilterByUsers("");
    setFilterValueUsers("");
  };

  // Sección de Docentes
  const [docente, setDocente] = useState([]);
  const [filteredDocente, setFilteredDocente] = useState([]);
  const [searchTermDocente, setSearchTermDocente] = useState("");
  const [filterByDocente, setFilterByDocente] = useState("");
  const [filterValueDocente, setFilterValueDocente] = useState("");

  const loadDocente = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/all_docentes.php`
    );
    setDocente(response.data);
    setFilteredDocente(response.data);
  };

  useEffect(() => {
    loadDocente();
  }, []);

  const handleSearchChangeDocente = (e) => {
    const searchTerm = e.target.value;
    setSearchTermDocente(searchTerm);
    const filteredDocenteData = filterData(
      users,
      searchTerm,
      filterByDocente,
      filterValueDocente
    );
    setFilteredDocente(filteredDocenteData);
  };

  const handleFilterChangeDocente = (e) => {
    setFilterByDocente(e.target.value);
    setFilterValueDocente("");
  };

  const handleValueChangeDocente = (e) => {
    setFilterValueDocente(e.target.value);
  };

  const handleClearFiltersDocente = () => {
    setSearchTermDocente("");
    setFilterByDocente("");
    setFilterValueDocente("");
  };

  // Sección de Asesores
  const [asesores, setAsesores] = useState([]);
  const [filteredAsesores, setFilteredAsesores] = useState([]);
  const [searchTermAsesores, setSearchTermAsesores] = useState("");
  const [filterByAsesores, setFilterByAsesores] = useState("");
  const [filterValueAsesores, setFilterValueAsesores] = useState("");

  const loadAsesores = async () => {
    const response = await axios.get(
      `${config.BASE_URL}/API_DualSystem/allAsesores.php`
    );
    setAsesores(response.data);
    setFilteredAsesores(response.data);
  };

  useEffect(() => {
    loadAsesores();
  }, []);

  const handleSearchChangeAsesores = (e) => {
    const searchTerm = e.target.value;
    setSearchTermAsesores(searchTerm);
    const filteredAsesoresData = filterData(
      users,
      searchTerm,
      filterByAsesores,
      filterValueAsesores
    );
    setFilteredAsesores(filteredAsesoresData);
  };

  const handleFilterChangeAsesores = (e) => {
    setFilterByAsesores(e.target.value);
    setFilterValueAsesores("");
  };

  const handleValueChangeAsesores = (e) => {
    setFilterValueAsesores(e.target.value);
  };

  const handleClearFiltersAsesores = () => {
    setSearchTermAsesores("");
    setFilterByAsesores("");
    setFilterValueAsesores("");
  };

  const [interesado, setInteresado] = useState([]);
  const InteresadoStatus = async () => {
    await axios
      .get(`${config.BASE_URL}/API_DualSystem/interesados.php`)
      .then((ui) => {
        setInteresado(ui.data);
      });
  };

  useEffect(() => {
    InteresadoStatus();
  }, []);

  const [modalOpenUser, setModalOpen] = useState(false); // Estado del primer modal
  const [modalOpenUserInteresado, setModalInteresadoOpen] = useState(false); // Estado del primer modal
  const [modalDocenteOpen, setModalDocenteOpen] = useState(false); // Estado del modal para docente
  const [modalAsesorOpen, setModalAsesorOpen] = useState(false); // Estado del modal para asesor
  const [modalVincularOpen, setModalVincularOpen] = useState(false); // Estado del modal para vincular docente-alumno

  const openModalUser = () => {
    setModalOpen(true);
  };

  const closeModalUser = () => {
    setModalOpen(false);
  };

  const openModalUserInteresado = () => {
    setModalInteresadoOpen(true);
  };

  const closeModalUserInteresado = () => {
    setModalInteresadoOpen(false);
  };

  const openModalDocente = () => {
    setModalDocenteOpen(true);
  };

  const closeModalDocente = () => {
    setModalDocenteOpen(false);
  };

  const openModalAsesor = () => {
    setModalAsesorOpen(true);
  };

  const closeModalAsesor = () => {
    setModalAsesorOpen(false);
  };

  const openModalVincular = () => {
    setModalVincularOpen(true);
  };

  const closeModalVincular = () => {
    setModalVincularOpen(false);
  };

  const [selectedUserToAdd, setSelectedUserToAdd] = useState(null);

  // Define una función para manejar el clic en el botón "Agregar" y que abra el modal con la información del usuario seleccionado
  const handleAddButtonClick = (user) => {
    setSelectedUserToAdd(user);
    openModalUserInteresado(); // Abre el modal
  };

  const [searchTermAlumnos, setSearchTermAlumnos] = useState("");

  return (
    <div className="container px-0 items-center">
      <div className="w-full flex space-x-[75%] items-center">
        <div className="w-40"></div>
        <UserId />
      </div>
      <div className="w-5/6 h-auto grid mx-auto">
        <div className="w-full p-5 h-auto items-center rounded-xl mx-auto content-center ">
          <div className="w-full text-center flex">
            <div>
              <img src={TECN} alt="logotec" className="w-40" />
            </div>
            <div className="mt-10">
              <p className="text-5xl font-bold text-[#233255]">
                Bienvenido Administrador
              </p>
              <div className="flex felx-cols-2">
                {" "}
                <div className="h-1 w-96 bg-blue-400"></div>
                <div className="h-1 w-48 bg-orange-500"></div>
              </div>
            </div>
          </div>
          <p className="font-bold text- text-blue-950 mt-8">AGREGAR</p>
          <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 mb-5" />
          {/**-------------------AQUI---------------- */}
          <div className="w-5/6 h-auto grid mx-auto">
            <div className="w-full p-5 h-auto grid grid-cols-4 mt-5 mb-5 rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
              <div className="gap-2 m-4">
                <button
                  onClick={openModalUser}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Estudiante
                </button>
                <Modal
                  isOpen={modalOpenUser}
                  onRequestClose={closeModalDocente}
                >
                  <button
                    onClick={closeModalUser}
                    className="text-red-600 font-extraboldbold text-4xl float-right"
                  >
                    X
                  </button>
                  <div className="gap-2 grid grid-cols-4">
                    <div className="col-span-4">
                      <p className="font-bold text-blue-950">Agregar Usuario</p>
                      <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                    </div>
                    <form
                      method="POST"
                      className="w-full col-span-4 grid grid-cols-4 grid-rows-4 gap-4 mx-auto"
                      action={`${config.BASE_URL}/API_DualSystem/registrar_user.php`}
                    >
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Correo
                        </label>
                        <input
                          placeholder="Correo"
                          name="correo"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Contraseña
                        </label>
                        <input
                          placeholder="Contraseña"
                          name="password"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Nombre
                        </label>
                        <input
                          placeholder="Nombre"
                          name="name"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Paterno
                        </label>
                        <input
                          placeholder="Apellido Paterno"
                          name="last_f"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Materno
                        </label>
                        <input
                          placeholder="Apellido Materno"
                          name="last_m"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          No. Control
                        </label>
                        <input
                          placeholder="Numero Control"
                          name="N_control"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Curp
                        </label>
                        <input
                          placeholder="Curp"
                          name="curp"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Telefono
                        </label>
                        <input
                          placeholder="Telefono"
                          name="phone"
                          type="number"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Fecha Nacimiento
                        </label>
                        <input
                          name="fecha"
                          type="date"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Dirección
                        </label>
                        <input
                          placeholder="Direccion"
                          name="adress"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Periodo
                        </label>
                        <input
                          name="period"
                          placeholder="Ejemplo: agosto 2023 - diciembre 2023"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2 mb-2">
                        <select
                          name="semestre"
                          id=""
                          className="mb-5 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">SEMESTRE</option>
                          <option value="1">Primer</option>
                          <option value="2">Segundo</option>
                          <option value="3">Tercero</option>
                          <option value="4">Cuarto</option>
                          <option value="5">Quinto</option>
                          <option value="6">Sexto</option>
                          <option value="7">Septimo</option>
                          <option value="8">Octavo</option>
                          <option value="9">Noveno</option>
                          <option value="10">Decimo</option>
                          <option value="11">Onceavo</option>
                          <option value="12">Doceavo</option>
                        </select>
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <select
                          name="carrera"
                          id=""
                          className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">Carrera</option>
                          <option value="111">
                            Ing. Sistemas Computacionales
                          </option>
                          <option value="222">Ing. Industrial</option>
                          <option value="333">Ing. Gestión Empresarial</option>
                          <option value="444">Ing. Nanotecnologia</option>
                          <option value="555">Ing. Mecatronica</option>
                          <option value="666">Ing. Electromecanica</option>
                          <option value="777">Ing. Electronica</option>
                          <option value="888">Ing. Geociencias</option>
                          <option value="999">Lic. Contabilidad</option>
                        </select>
                      </div>
                      <div className="col-span-2 mb-2">
                        <button
                          type="submit"
                          id="env"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Registrar
                        </button>
                      </div>
                    </form>
                  </div>
                </Modal>
                <Modal
                  isOpen={modalOpenUserInteresado}
                  onRequestClose={closeModalUserInteresado}
                >
                  <button
                    onClick={closeModalUser}
                    className="text-red-600 font-extraboldbold text-4xl float-right"
                  >
                    X
                  </button>
                  <div className="gap-2 grid grid-cols-4">
                    <div className="col-span-4">
                      <p className="font-bold text-blue-950">
                        Agregar Usuario Interesado:
                      </p>
                      <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                    </div>
                    <form
                      method="POST"
                      className="w-full col-span-4 grid grid-cols-4 grid-rows-4 gap-4 mx-auto"
                      action={`${config.BASE_URL}/API_DualSystem/registrar_user.php`}
                    >
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Correo
                        </label>
                        <input
                          placeholder="Correo"
                          name="correo"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.email : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Contraseña
                        </label>
                        <input
                          placeholder="Contraseña"
                          name="password"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.password : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Nombre
                        </label>
                        <input
                          placeholder="Nombre"
                          name="name"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.name : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Paterno
                        </label>
                        <input
                          placeholder="Apellido Paterno"
                          name="last_f"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.last_f : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Materno
                        </label>
                        <input
                          placeholder="Apellido Materno"
                          name="last_m"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.last_m : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          No. Control
                        </label>
                        <input
                          placeholder="Numero Control"
                          name="N_control"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.N_control : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Curp
                        </label>
                        <input
                          placeholder="Curp"
                          name="curp"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.curp : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Telefono
                        </label>
                        <input
                          placeholder="Telefono"
                          name="phone"
                          type="number"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.phone : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Fecha Nacimiento
                        </label>
                        <input
                          name="fecha"
                          type="date"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.fecha : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Dirección
                        </label>
                        <input
                          placeholder="Direccion"
                          name="adress"
                          type="text"
                          value={
                            selectedUserToAdd ? selectedUserToAdd.adress : ""
                          }
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Periodo
                        </label>
                        <input
                          name="period"
                          placeholder="Ejemplo: agosto 2023 - diciembre 2023"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                      <div className="col-span-2 mb-2">
                        <select
                          name="semestre"
                          id=""
                          value={
                            selectedUserToAdd
                              ? selectedUserToAdd.id_semestre
                              : ""
                          }
                          className="mb-5 mt-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">SEMESTRE</option>
                          <option value="1">Primer</option>
                          <option value="2">Segundo</option>
                          <option value="3">Tercero</option>
                          <option value="4">Cuarto</option>
                          <option value="5">Quinto</option>
                          <option value="6">Sexto</option>
                          <option value="7">Septimo</option>
                          <option value="8">Octavo</option>
                          <option value="9">Noveno</option>
                          <option value="10">Decimo</option>
                          <option value="11">Onceavo</option>
                          <option value="12">Doceavo</option>
                        </select>
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <select
                          name="carrera"
                          id=""
                          value={
                            selectedUserToAdd
                              ? selectedUserToAdd.id_carrera
                              : ""
                          }
                          className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">Carrera</option>
                          <option value="111">
                            Ing. Sistemas Computacionales
                          </option>
                          <option value="222">Ing. Industrial</option>
                          <option value="333">Ing. Gestión Empresarial</option>
                          <option value="444">Ing. Nanotecnologia</option>
                          <option value="555">Ing. Mecatronica</option>
                          <option value="666">Ing. Electromecanica</option>
                          <option value="777">Ing. Electronica</option>
                          <option value="888">Ing. Geociencias</option>
                          <option value="999">Lic. Contabilidad</option>
                        </select>
                      </div>
                      <div className="col-span-2 mb-2">
                        <button
                          type="submit"
                          id="env"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Registrar
                        </button>
                      </div>
                    </form>
                  </div>
                </Modal>
              </div>
              <div className="gap-2 m-4">
                <button
                  onClick={openModalDocente}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Docente
                </button>
                <Modal
                  isOpen={modalDocenteOpen}
                  onRequestClose={closeModalDocente}
                >
                  <button
                    onClick={closeModalDocente}
                    className="text-red-600 font-extraboldbold text-4xl float-right"
                  >
                    X
                  </button>
                  <div className="gap-2 ml-6 grid grid-cols-4">
                    <div className="col-span-4">
                      <p className="font-bold text-blue-950">Agregar Docente</p>
                      <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                    </div>
                    <form
                      method="POST"
                      className="w-full col-span-4 grid grid-cols-4 grid-rows-4 gap-4 mx-auto"
                      action={`${config.BASE_URL}/API_DualSystem/registrar_docente.php`}
                    >
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Correo
                        </label>
                        <input
                          placeholder="Correo"
                          name="correo"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Contraseña
                        </label>
                        <input
                          placeholder="Contraseña"
                          name="password"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Nombre
                        </label>
                        <input
                          placeholder="Nombre"
                          name="name"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Paterno
                        </label>
                        <input
                          placeholder="Apellido Paterno"
                          name="last_f"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Materno
                        </label>
                        <input
                          placeholder="Apellido Materno"
                          name="last_m"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          No. Control
                        </label>
                        <input
                          placeholder="Numero Control"
                          name="N_control"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Telefono
                        </label>
                        <input
                          placeholder="Telefono"
                          name="phone"
                          type="number"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <select
                          name="carrera"
                          id=""
                          className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">carrera</option>
                          <option value="111">
                            Ing. Sistemas Computacionales
                          </option>
                          <option value="222">Ing. Industrial</option>
                          <option value="333">Ing. Gestión Empresarial</option>
                          <option value="444">Ing. Nanotecnologia</option>
                          <option value="555">Ing. Mecatronica</option>
                          <option value="666">Ing. Electromecanica</option>
                          <option value="777">Ing. Electronica</option>
                          <option value="888">Ing. Geociencias</option>
                          <option value="999">Lic. Contabilidad</option>
                        </select>
                      </div>
                      <div className="col-span-2 mb-2">
                        <button
                          type="submit"
                          id="env"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Registrar
                        </button>
                      </div>
                    </form>
                  </div>
                </Modal>
              </div>

              <div className="gap-2 m-4">
                {/* Botón y modal para asesor */}
                <button
                  onClick={openModalAsesor}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Asesor
                </button>
                <Modal
                  isOpen={modalAsesorOpen}
                  onRequestClose={closeModalAsesor}
                >
                  {/* Contenido del Modal para asesor */}
                  <button
                    onClick={closeModalAsesor}
                    className="text-red-600 font-extraboldbold text-4xl float-right"
                  >
                    X
                  </button>
                  <div className="gap-2 ml-6 grid grid-cols-4">
                    <div className="col-span-4">
                      <p className="font-bold text-blue-950">
                        Agregar Asesor
                      </p>
                      <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                    </div>
                    <form
                      method="POST"
                      className="w-full col-span-4 grid grid-cols-4 grid-rows-4 gap-4 mx-auto"
                      action={`${config.BASE_URL}/API_DualSystem/registro_Asesor.php`}
                    >
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Correo
                        </label>
                        <input
                          placeholder="Correo"
                          name="correo"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Contraseña
                        </label>
                        <input
                          placeholder="Contraseña"
                          name="password"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Nombre
                        </label>
                        <input
                          placeholder="Nombre"
                          name="name"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Paterno
                        </label>
                        <input
                          placeholder="Apellido Paterno"
                          name="last_f"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Apellido Materno
                        </label>
                        <input
                          placeholder="Apellido Materno"
                          name="last_m"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Empresa
                        </label>
                        <input
                          placeholder="Empresa"
                          name="empresa"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Telefono
                        </label>
                        <input
                          placeholder="Telefono"
                          name="phone"
                          type="number"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Area
                        </label>
                        <input
                          placeholder="Area"
                          name="area"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <label className="font-bold text-blue-950" htmlFor="">
                          Ubicación
                        </label>
                        <input
                          placeholder="Ubicación"
                          name="adres"
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>{" "}
                      <div className="col-span-2 mb-2">
                        <select
                          name="carrera"
                          id=""
                          className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="#">Carrera</option>
                          <option value="111">
                            Ing. Sistemas Computacionales
                          </option>
                          <option value="222">Ing. Industrial</option>
                          <option value="333">Ing. Gestión Empresarial</option>
                          <option value="444">Ing. Nanotecnologia</option>
                          <option value="555">Ing. Mecatronica</option>
                          <option value="666">Ing. Electromecanica</option>
                          <option value="777">Ing. Electronica</option>
                          <option value="888">Ing. Geociencias</option>
                          <option value="999">Lic. Contabilidad</option>
                        </select>
                      </div>
                      <div className="col-span-2 mb-2">
                        <button
                          type="submit"
                          id="env"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Registrar
                        </button>
                      </div>
                    </form>
                  </div>
                </Modal>
              </div>

              <div className="gap-2 m-4">
                {/* Botón y modal para vincular docente-alumno */}
                <button
                  onClick={openModalVincular}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Vincular Estudiante
                </button>
                <Modal
                  isOpen={modalVincularOpen}
                  onRequestClose={closeModalVincular}
                >
                  {/* Contenido del Modal para vincular docente-alumno */}
                  <button
                    onClick={closeModalVincular}
                    className="text-red-600 font-extraboldbold text-4xl float-right"
                  >
                    X
                  </button>
                  <div className="gap-2 ml-6">
                    <div className="col-span-4">
                      <p className="font-bold text-blue-950">
                        Vincular Docente-Alumno
                      </p>
                      <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700" />
                    </div>
                    <form
                      method="POST"
                      action={`${config.BASE_URL}/API_DualSystem/asignar_docente.php`}
                    >
                      <select
                        name="asesor"
                        className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="#">Selecciona un Asesor</option>
                        {asesores.map((user) => (
                          <option
                            key={user.id_asesor}
                            value={user.id_asesor}
                          >{`${user.name_a} ${user.apep_a} ${user.apem_a}`}</option>
                        ))}
                      </select>

                      <select
                        name="docente"
                        className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="#">Selecciona un docente</option>
                        {docente.map((user) => (
                          <option
                            key={user.ID_docente}
                            value={user.ID_docente}
                          >{`${user.name_d} ${user.apep_d} ${user.apem_d}`}</option>
                        ))}
                      </select>

                      <input
                        type="text"
                        value={searchTermAlumnos}
                        onChange={(e) => setSearchTermAlumnos(e.target.value)}
                        placeholder="Buscar alumno por nombre o número de control"
                        className="px-4 w-96 py-2 rounded-md border border-gray-300 shadow-sm mb-4 focus:outline-none focus:border-blue-500"
                      />

                      <select
                        name="alumno"
                        className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="#">Selecciona un Alumno</option>
                        {users
                          .filter(
                            (user) =>
                              `${user.name} ${user.last_f} ${user.last_m}`
                                .toLowerCase()
                                .includes(searchTermAlumnos.toLowerCase()) ||
                              (user.N_control &&
                                user.N_control.toString()
                                  .toLowerCase()
                                  .includes(searchTermAlumnos.toLowerCase()))
                          )
                          .map((user) => (
                            <option
                              key={user.ID_student}
                              value={user.ID_student}
                            >
                              {`${user.name} ${user.last_f} ${user.last_m} ${
                                user.N_control
                              } - ${
                                user.id_semestre == 1
                                  ? "primero"
                                  : user.id_semestre == 2
                                  ? "Segundo"
                                  : user.id_semestre == 3
                                  ? "Tercero"
                                  : user.id_semestre == 4
                                  ? "Cuarto"
                                  : user.id_semestre == 5
                                  ? "Quinto"
                                  : user.id_semestre == 6
                                  ? "Sexto"
                                  : user.id_semestre == 7
                                  ? "Séptimo"
                                  : user.id_semestre == 8
                                  ? "Octavo"
                                  : user.id_semestre == 9
                                  ? "Noveno"
                                  : user.id_semestre == 10
                                  ? "Décimo"
                                  : user.id_semestre == 11
                                  ? "Onceavo"
                                  : user.id_semestre == 12
                                  ? "Doceavo"
                                  : "No cursando"
                              }
      -
      ${
        user.id_carrera == 111
          ? "Ing. Sistemas Computacionales"
          : user.id_carrera == 222
          ? "Ing. Industrial"
          : user.id_carrera == 333
          ? "Ing. Gestión Empresarial"
          : user.id_carrera == 444
          ? "Ing. Nanotecnología"
          : user.id_carrera == 555
          ? "Ing. Mecatrónica"
          : user.id_carrera == 666
          ? "Ing. Electromecánica"
          : user.id_carrera == 777
          ? "Ing. Electrónica"
          : user.id_carrera == 888
          ? "Ing. Geociencias"
          : user.id_carrera == 999
          ? "Lic. Contabilidad"
          : "Ingeniero"
      }
      `}
                            </option>
                          ))}
                      </select>

                      <select
                        name="semestre"
                        className="mb-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-slate-100 dark:border-slate-100 dark:placeholder-gray-400 dark:text-blue-950 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="#">Selecciona un Semestre</option>

                        <option value="1">Primero</option>
                        <option value="2">Segundo</option>
                        <option value="3">Tercer</option>
                        <option value="4">Cuarto</option>
                        <option value="5">Quinto</option>
                        <option value="6">Sexto</option>
                        <option value="7">Septimo</option>
                        <option value="8">Octavo</option>
                        <option value="9">Noveno</option>
                        <option value="10">Decimo</option>
                        <option value="11">Onceavo</option>
                        <option value="12">Doceavo</option>
                      </select>

                      <button
                        type="submit"
                        id="env"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Registrar
                      </button>
                    </form>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          {/**-------------------AQUI---------------- */}
          {/* Sección de Usuarios Inscritos */}
          <div>
            <p className="font-bold text-blue-950 mt-5">Usuarios Inscritos</p>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 mb-5" />
            <div className="mt-8">
              <div className="flex justify-between mb-4">
                <input
                  type="text"
                  placeholder="Buscar"
                  value={searchTermUsers}
                  onChange={handleSearchChangeUsers}
                  className="h-10 px-3 w-96 bg-gray-200 rounded-lg"
                />
                <select
                  value={filterByUsers}
                  onChange={handleFilterChangeUsers}
                  className="h-10 px-3 bg-gray-200 rounded-lg"
                >
                  <option value="">Filtrar por</option>
                  <option value="id_carrera">Carrera</option>
                  <option value="id_semestre">Semestre</option>
                </select>
                {filterByUsers === "id_carrera" && (
                  <select
                    value={filterValueUsers}
                    onChange={handleValueChangeUsers}
                    className="ml-2 h-10 px-3 bg-gray-200 rounded-lg"
                  >
                    <option value="">Seleccionar Carrera</option>
                    <option value="111">Ing. Sistemas Computacionales</option>
                    <option value="222">Ing. Industrial</option>
                    <option value="333">Ing. Gestión Empresarial</option>
                    {/* Agrega más opciones de carrera si es necesario */}
                  </select>
                )}
                {filterByUsers === "id_semestre" && (
                  <select
                    value={filterValueUsers}
                    onChange={handleValueChangeUsers}
                    className="ml-2 h-10 px-3 bg-gray-200 rounded-lg"
                  >
                    <option value="">Seleccionar Semestre</option>
                    <option value="1">1er Semestre</option>
                    <option value="2">2do Semestre</option>
                    {/* Agrega más opciones de semestre si es necesario */}
                  </select>
                )}
                <button
                  onClick={handleClearFiltersUsers}
                  className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
                >
                  Limpiar Filtros
                </button>
              </div>
              {/* Tabla para mostrar usuarios filtrados */}
              <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
                {/* Cabecera de la tabla */}
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
                  {/* Encabezados de columna */}
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Nombre Completo
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Correo
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Contraseña
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No° Control
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Curp
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Teléfono
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Carrera
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Semestre
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Eliminar
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Filas de datos de usuarios */}
                  {filterData(
                    filteredUsers,
                    searchTermUsers,
                    filterByUsers,
                    filterValueUsers
                  ).map((db, index) => (
                    <tr
                      key={db.ID_student}
                      className={`${
                        index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                      } border-b`}
                    >
                      <td className="px-6 py-4">{`${db.name} ${db.last_f} ${db.last_m}`}</td>
                      <td className="px-6 py-4">{db.email}</td>
                      <td className="px-6 py-4">{db.password}</td>
                      <td className="px-6 py-4">{db.N_control}</td>
                      <td className="px-1 py-1 text-xs">{db.curp}</td>
                      <td className="px-6 py-4">{db.phone}</td>
                      <td className="px-6 py-4">
                        {db.id_carrera == 111
                          ? "Ing. Sistemas Computacionales"
                          : db.id_carrera == 222
                          ? "Ing. Industrial"
                          : db.id_carrera == 333
                          ? "Ing. Gestión Empresarial"
                          : db.id_carrera == 444
                          ? "Ing. Nanotecnología"
                          : db.id_carrera == 555
                          ? "Ing. Mecatrónica"
                          : db.id_carrera == 666
                          ? "Ing. Electromecánica"
                          : db.id_carrera == 777
                          ? "Ing. Electrónica"
                          : db.id_carrera == 888
                          ? "Ing. Geociencias"
                          : db.id_carrera == 999
                          ? "Lic. Contabilidad"
                          : "Ingeniero"}
                      </td>
                      <td className="px-6 py-4">
                        {db.id_semestre == 1
                          ? "primero"
                          : db.id_semestre == 2
                          ? "Segundo"
                          : db.id_semestre == 3
                          ? "Tercero"
                          : db.id_semestre == 4
                          ? "Cuarto"
                          : db.id_semestre == 5
                          ? "Quinto"
                          : db.id_semestre == 6
                          ? "Sexto"
                          : db.id_semestre == 7
                          ? "Séptimo"
                          : db.id_semestre == 8
                          ? "Octavo"
                          : db.id_semestre == 9
                          ? "Noveno"
                          : db.id_semestre == 10
                          ? "Décimo"
                          : db.id_semestre == 11
                          ? "Onceavo"
                          : db.id_semestre == 12
                          ? "Doceavo"
                          : "No cursando"}
                      </td>
                      <td>
                        <form
                          method="POST"
                          action={`${config.BASE_URL}/API_DualSystem/delete.php`}
                        >
                          <input
                            name="id"
                            type="hidden"
                            value={db.ID_student}
                          />
                          <button className="bg-red-600 font-bold">
                            Eliminar
                          </button>
                        </form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Sección de Docentes */}
          <div>
            <p className="font-bold text-blue-950 mt-5">Docentes</p>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 mb-5" />
            <div className="mt-8">
              <div className="flex justify-between mb-4">
                <input
                  type="text"
                  placeholder="Buscar"
                  value={searchTermDocente}
                  onChange={handleSearchChangeDocente}
                  className="h-10 px-3 w-96 bg-gray-200 rounded-lg"
                />
                <button
                  onClick={handleClearFiltersDocente}
                  className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
                >
                  Limpiar Filtros
                </button>
              </div>
              {/* Tabla para mostrar docentes filtrados */}
              <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
                {/* Cabecera de la tabla */}
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
                  <th scope="col" className="px-6 py-3">
                    Nombre Completo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Correo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Contraseña
                  </th>
                  <th scope="col" className="px-6 py-3">
                    No° Control
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Telefono
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Carrera
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Eliminar
                  </th>
                </thead>
                <tbody>
                  {docente.map((db, index) => (
                    <tr
                      key={db.ID_docente}
                      className={`${
                        index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                      } border-b`}
                    >
                      <td className="px-6 py-4">{`${db.name_d} ${db.apep_d} ${db.apem_d}`}</td>
                      <td className="px-6 py-4">{db.email}</td>
                      <td className="px-6 py-4">{db.password}</td>
                      <td className="px-6 py-4">{db.n_control_d}</td>

                      <td className="px-6 py-4">{db.phone_d}</td>
                      <td className="px-6 py-4">
                        {db.id_carrera_d == 111
                          ? "Ing. Sistemas Computacionales"
                          : db.id_carrera_d == 222
                          ? "Ing. Industrial"
                          : db.id_carrera_d == 333
                          ? "Ing. Gestión Empresarial"
                          : db.id_carrera_d == 444
                          ? "Ing. Nanotecnología"
                          : db.id_carrera_d == 555
                          ? "Ing. Mecatrónica"
                          : db.id_carrera_d == 666
                          ? "Ing. Electromecánica"
                          : db.id_carrera_d == 777
                          ? "Ing. Electrónica"
                          : db.id_carrera_d == 888
                          ? "Ing. Geociencias"
                          : db.id_carrera_d == 999
                          ? "Lic. Contabilidad"
                          : "Ingeniero"}
                      </td>

                      <td className="px-6 py-4">
                        <form
                          method="POST"
                          action={`${config.BASE_URL}/API_DualSystem/deleteD.php`}
                        >
                          <input
                            name="id"
                            type="hidden"
                            value={db.ID_docente}
                          />
                          <button className="bg-red-600 font-bold">
                            Eliminar
                          </button>
                        </form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Sección de Asesores */}
          <div>
            <p className="font-bold text-blue-950 mt-5">Asesores</p>
            <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 mb-5" />
            <div className="mt-8">
              <div className="flex justify-between mb-4">
                <input
                  type="text"
                  placeholder="Buscar"
                  value={searchTermAsesores}
                  onChange={handleSearchChangeAsesores}
                  className="h-10 px-3 w-96 bg-gray-200 rounded-lg"
                />
                <button
                  onClick={handleClearFiltersAsesores}
                  className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
                >
                  Limpiar Filtros
                </button>
              </div>
              {/* Tabla para mostrar asesores filtrados */}
              <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
                {/* Cabecera de la tabla */}
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
                  <th scope="col" className="px-6 py-3">
                    Nombre Completo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Correo
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Contraseña
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Empresa
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Area
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Direccion
                  </th>
                  <th scope="col" className="px-6 py-3">
                    telefono
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Carrera
                  </th>
                  <td scope="col" className="px-6 py-3">
                    Eliminar
                  </td>
                </thead>
                <tbody>
                  {asesores.map((db, index) => (
                    <tr
                      key={db.ID_asesor}
                      className={`${
                        index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                      } border-b`}
                    >
                      <td className="px-6 py-4">{`${db.name_a} ${db.apep_a} ${db.apem_a}`}</td>
                      <td className="px-6 py-4">{db.email}</td>
                      <td className="px-6 py-4">{db.password}</td>
                      <td className="px-6 py-4">{db.empresa_a}</td>
                      <td className="px-6 py-4">{db.area_a}</td>
                      <td className="px-6 py-4">{db.adress_a}</td>
                      <td className="px-6 py-4">{db.phone_a}</td>
                      <td className="px-6 py-4">
                        {db.id_carrera_a == 111
                          ? "Ing. Sistemas Computacionales"
                          : db.id_carrera_a == 222
                          ? "Ing. Industrial"
                          : db.id_carrera_a == 333
                          ? "Ing. Gestión Empresarial"
                          : db.id_carrera_a == 444
                          ? "Ing. Nanotecnología"
                          : db.id_carrera_a == 555
                          ? "Ing. Mecatrónica"
                          : db.id_carrera_a == 666
                          ? "Ing. Electromecánica"
                          : db.id_carrera_a == 777
                          ? "Ing. Electrónica"
                          : db.id_carrera_a == 888
                          ? "Ing. Geociencias"
                          : db.id_carrera_a == 999
                          ? "Lic. Contabilidad"
                          : "Ingeniero"}
                      </td>

                      <td>
                        <form
                          method="POST"
                          action={`${config.BASE_URL}/API_DualSystem/deleteA.php`}
                        >
                          <input name="id" type="hidden" value={db.id_asesor} />
                          <button className="bg-red-600 font-bold">
                            Eliminar
                          </button>
                        </form>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div>
              <p className="font-bold text-blue-950 mt-5">
                Usuarios Interesados
              </p>
              <hr className="h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700 mb-5" />
              <div className="mt-8">
                {/* Tabla para mostrar usuarios filtrados */}
                <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
                  {/* Cabecera de la tabla */}
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
                    {/* Encabezados de columna */}
                    <tr>
                      <th scope="col" className="px-6 py-3">
                        Nombre Completo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Correo
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Contraseña
                      </th>
                      <th scope="col" className="px-6 py-3">
                        No° Control
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Curp
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Teléfono
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Carrera
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Semestre
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Solicitado por:
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Alta
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Eliminar
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {interesado.map((item, index) => (
                      <tr
                        key={item.user_id}
                        className={`${
                          index % 2 === 0 ? "bg-gray-300" : "bg-gray-100"
                        } border-b`}
                      >
                        <td className="px-6 py-4">{`${item.name} ${item.last_f} ${item.last_m}`}</td>
                        <td className="px-6 py-4">{item.email}</td>
                        <td className="px-6 py-4">{item.password}</td>
                        <td className="px-6 py-4">{item.N_control}</td>
                        <td className="px-1 py-1 text-xs">{item.curp}</td>
                        <td className="px-1 py-1 text-xs">{item.phone}</td>
                        <td className="px-6 py-4">
                          {item.id_carrera == 111
                            ? "Ing. Sistemas Computacionales"
                            : item.id_carrera == 222
                            ? "Ing. Industrial"
                            : item.id_carrera == 333
                            ? "Ing. Gestión Empresarial"
                            : item.id_carrera == 444
                            ? "Ing. Nanotecnología"
                            : item.id_carrera == 555
                            ? "Ing. Mecatrónica"
                            : item.id_carrera == 666
                            ? "Ing. Electromecánica"
                            : item.id_carrera == 777
                            ? "Ing. Electrónica"
                            : item.id_carrera == 888
                            ? "Ing. Geociencias"
                            : item.id_carrera == 999
                            ? "Lic. Contabilidad"
                            : "Ingeniero"}
                        </td>
                        <td className="px-6 py-4">
                          {item.id_semestre == 1
                            ? "Primero"
                            : item.id_semestre == 2
                            ? "Segundo"
                            : item.id_semestre == 3
                            ? "Tercero"
                            : item.id_semestre == 4
                            ? "Cuarto"
                            : item.id_semestre == 5
                            ? "Quinto"
                            : item.id_semestre == 6
                            ? "Sexto"
                            : item.id_semestre == 7
                            ? "Séptimo"
                            : item.id_semestre == 8
                            ? "Octavo"
                            : item.id_semestre == 9
                            ? "Noveno"
                            : item.id_semestre == 10
                            ? "Décimo"
                            : item.id_semestre == 11
                            ? "Onceavo"
                            : item.id_semestre == 12
                            ? "Doceavo"
                            : "No cursando"}
                        </td>
                        <td
                          className={`px-6 py-4 ${
                            item.estatus === "1"
                              ? "text-green-500"
                              : "text-blue-500"
                          }`}
                        >
                          {item.estatus === "1" ? "En Espera" : item.estatus}
                        </td>
                        <td>
                          <button
                            onClick={() => handleAddButtonClick(item)}
                            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                          >
                            Agregar
                          </button>
                        </td>
                        <td>
                          <form
                            method="POST"
                            action={`${config.BASE_URL}/API_DualSystem/deleteInteresado.php`}
                          >
                            <input
                              name="id"
                              type="hidden"
                              value={item.user_id}
                            />
                            <button className="bg-red-600 font-bold">
                              Eliminar
                            </button>
                          </form>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
