import Menu from "../components/Menu";
import TECN from "../Resources/TECNpng.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { BsClipboardData } from "react-icons/bs";
import Reticula from "../components/reticula";
import config from "../config/env";

let name = localStorage.getItem("name");
let apep = localStorage.getItem("apep");

export default function ReportsC() {
  const [boleta, setBoleta] = useState([]);
  const [tutor, setTutor] = useState([]);
  const [docente, setDocente] = useState([]);

  const BoletaStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/boleta.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((bo) => {
        setBoleta(bo.data);
      });
  };
  useEffect(() => {
    BoletaStatus();
  }, []);

  const tutorStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/tutor.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((db) => {
        setTutor(db.data);
      });
  };
  useEffect(() => {
    tutorStatus();
  }, []);

  const docenteStatus = async () => {
    await axios
      .get(
        `${config.BASE_URL}/API_DualSystem/docente.php?id=${localStorage.getItem(
          "id"
        )}`
      )
      .then((db) => {
        setDocente(db.data);
      });
  };
  useEffect(() => {
    docenteStatus();
  }, []);

  return (
    <div className="mx-auto max-w">
      
      {/* Header */}
      <div className="w-full bg-gray-800 text-white py-4 flex items-center justify-between mb-8">
        <div className="ml-6 flex items-center">
          <img src={TECN} alt="logotec" className="w-16" />
          <p className="text-xl font-bold ml-2">ESTUDIANTE → Bienvenido.</p>
          <p className="text-xl font-bold ml-2">
            {name} {apep}
          </p>
        </div>
      </div>

      <Menu />

      {!boleta || boleta.length === 0 ? (
        <div className="w-5/6 h-full grid mx-auto">
          <p className="font-bold text-center text-blue-950 text-4xl">
            Calificaciones
          </p>
          <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />

          <p className="text-red-600 font-extrabold text-5xl text-center">
            Oops, parece que aun no tienes calificaciones!!!
          </p>
        </div>
      ) : (
        boleta.map(function curp(calificaciones) {
          if (calificaciones) {
            return (
              <div className="w-5/6 h-full grid mx-auto">
                <p className="font-bold text-center text-blue-950 text-4xl">
                  Calificaciones
                </p>
                <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />
                <div className="grid grid-cols-3">
                  <div className="p-4 w-64 h-64 mt-2 rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <div className="text-sm w-20 h-20 border-solid border-2 border-gray-900 text-center flex items-center justify-center  rounded-full shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                      <BsClipboardData size={"4rem"} />
                    </div>
                    {tutor.map((calificacion_asesor) => (
                      <div>
                        <p className="text-orange-500 font-semibold text-2xl">
                          1° Parcial
                        </p>
                        <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-900" />
                        {docente.map((calificacion_docente) => (
                          <p>
                            Tutor Academico -----{" "}
                            {calificacion_docente.parcial_1}{" "}
                          </p>
                        ))}
                        <p>
                          Asesor ----------------{" "}
                          {calificacion_asesor.parcial_1}{" "}
                        </p>
                      </div>
                    ))}
                    {calificaciones.parcial_1 >= 70 ? (
                      <p className="text-center font-bold text-green-500 text-4xl">
                        {calificaciones.parcial_1}
                      </p>
                    ) : (
                      <p className="text-center font-bold text-red-600 text-4xl">
                        NA
                      </p>
                    )}

                    <p className="text-center font-semibold text-sm">
                      Calificación Global
                    </p>
                  </div>

                  <div className="p-4 w-64 h-64 mt-2 rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <div className="text-sm w-20 h-20 border-solid border-2 border-gray-900 text-center flex items-center justify-center  rounded-full shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                      <BsClipboardData size={"4rem"} />
                    </div>
                    {tutor.map((calificacion_asesor) => (
                      <div>
                        <p className="text-orange-500 font-semibold text-2xl">
                          2° Parcial
                        </p>
                        <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-900" />
                        {docente.map((calificacion_docente) => (
                          <p>
                            Tutor Academico -----{" "}
                            {calificacion_docente.parcial_2}{" "}
                          </p>
                        ))}
                        <p>
                          Asesor ----------------{" "}
                          {calificacion_asesor.parcial_2}{" "}
                        </p>
                      </div>
                    ))}
                    {calificaciones.parcial_2 >= 70 ? (
                      <p className="text-center font-bold text-green-500 text-4xl">
                        {calificaciones.parcial_2}
                      </p>
                    ) : (
                      <p className="text-center font-bold text-red-600 text-4xl">
                        NA
                      </p>
                    )}
                    <p className="text-center font-semibold text-sm">
                      Calificación Global
                    </p>
                  </div>

                  <div className="p-4 w-64 h-64 mt-2 rounded-xl shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                    <div className="text-sm w-20 h-20 border-solid border-2 border-gray-900 text-center flex items-center justify-center  rounded-full shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
                      <BsClipboardData size={"4rem"} />
                    </div>
                    {tutor.map((calificacion_asesor) => (
                      <div>
                        <p className="text-orange-500 font-semibold text-2xl">
                          3° Parcial
                        </p>
                        <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-900" />
                        {docente.map((calificacion_docente) => (
                          <p>
                            Tutor Academico -----{" "}
                            {calificacion_docente.parcial_3}{" "}
                          </p>
                        ))}
                        <p>
                          Asesor ----------------{" "}
                          {calificacion_asesor.parcial_3}{" "}
                        </p>
                      </div>
                    ))}
                    {calificaciones.parcial_3 >= 70 ? (
                      <p className="text-center font-bold text-green-500 text-4xl">
                        {calificaciones.parcial_3}
                      </p>
                    ) : (
                      <p className="text-center font-bold text-red-600 text-4xl">
                        NA
                      </p>
                    )}
                    <p className="text-center font-semibold text-sm">
                      Calificación Global
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        })
      )}

      <div className="ml-36">
        <div className="mx-auto">
          <p className="text-center font-bold text-blue-950 text-2xl">
            Reticula
          </p>
          <hr className="bottom-0 w-11/12 h-1 m-1 bg-cyan-950-200 border-0 dark:bg-orange-600" />
          <Reticula />
        </div>
      </div>
    </div>
  );
}
