import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config/env";

const carreras = [
  { id: 111, name: 'Ing. Sistemas Computacionales' },
  { id: 222, name: 'Ing. Industrial' },
  { id: 333, name: 'Ing. Gestión Empresarial' },
  { id: 444, name: 'Ing. Nanotecnología' },
  { id: 555, name: 'Ing. Mecatrónica' },
  { id: 666, name: 'Ing. Electromecánica' },
  { id: 777, name: 'Ing. Electrónica' },
  { id: 888, name: 'Ing. Geociencias' },
  { id: 999, name: 'Lic. Contabilidad' },
];

const semestres = [
  { id: 1, name: 'Primero' },
  { id: 2, name: 'Segundo' },
  { id: 3, name: 'Tercero' },
  { id: 4, name: 'Cuarto' },
  { id: 5, name: 'Quinto' },
  { id: 6, name: 'Sexto' },
  { id: 7, name: 'Séptimo' },
  { id: 8, name: 'Octavo' },
  { id: 9, name: 'Noveno' },
  { id: 10, name: 'Décimo' },
  { id: 11, name: 'Onceavo' },
  { id: 12, name: 'Doceavo' },
  { id: 0, name: 'No cursando' },
];

export default function TableUser() {
  const [userData, setUserData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [searchPeriod, setSearchPeriod] = useState('');

  const fetchData = async () => {
    try {
      const userResponse = await axios.get(`${config.BASE_URL}/API_DualSystem/userT.php?id=${localStorage.getItem("did")}`);
      setUserData(userResponse.data);
      setFilteredUserData(userResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
        // Filtrar usuarios basado en el término de búsqueda, el periodo y el filtro seleccionado
    const filteredUsers = userData.filter(user => {
      const fullName = `${user.name ?? ''} ${user.last_f ?? ''} ${user.last_m ?? ''}`.toLowerCase();
      const controlNumber = (user.N_control ?? '').toString().toLowerCase();
      let matchFilter = true;
  
      if (filterBy === 'carrera') {
        matchFilter = user.id_carrera?.toString() === filterValue?.toString();
      } else if (filterBy === 'semestre') {
        matchFilter = user.id_semestre?.toString() === filterValue?.toString();
      }
  // Filtrar también por periodo, verificando si user.periodo está presente
  const matchPeriod = user.periodo ? user.periodo.toLowerCase().includes(searchPeriod.toLowerCase()) : true;
  return (fullName.includes(searchTerm.toLowerCase()) || controlNumber.includes(searchTerm.toLowerCase())) && matchFilter && matchPeriod;
    });
  
    setFilteredUserData(filteredUsers);
  }, [searchTerm, userData, filterBy, filterValue, searchPeriod]);
  

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  const handleFilterChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterBy(selectedFilter);
    // Si se selecciona el filtro de carrera, restablecer el valor del filtro de carrera
    if (selectedFilter === 'carrera' || selectedFilter === 'semestre') {
      setFilterValue('');
    }
    setSearchTerm('');
  };
  
  const handleFilterValueChange = (event) => {
    const selectedValue = event.target.value;
    setFilterValue(selectedValue);
    setSearchTerm('');
  };

  const handleClearFilters = () => {
    setFilterBy('');
    setFilterValue('');
    setSearchTerm('');
    setSearchPeriod('');
  };

  const handlePeriodChange = (event) => {
    setSearchPeriod(event.target.value);
  };

  return (
    <div className="">
      <form>
        {userData.map((user, index) => (
          <React.Fragment key={user.ID_student}>
            {index === 0 && (
              <div className="mb-4">
                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Tutor Académico
                  </label>
                  <input
                    readOnly
                    style={{
                      minWidth: `${(user.name_d.length + user.apep_d.length + user.apem_d.length + 1) * 9}px`,
                    }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={`${user.name_d} ${user.apep_d} ${user.apem_d}`}
                  />
                </div>

                <div className="grid grid-rows-2 space-x-2">
                  <label className="block text-gray-800 font-bold text-base text-left">
                    Carrera
                  </label>
                  <input
                    readOnly
                    style={{ minWidth: '270px' }}
                    className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                    type="text"
                    value={
                      carreras.find(carrera => carrera.id === user.id_carrera_d)?.name || 'Ingeniero'
                    }
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}

        <div className="flex flex-col-4 w-3/4 h-auto">
          <div className="col-span-3 mr-4">
            <input
              type="text"
              placeholder="Nombre o No. de control"
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-10 p-2 bg-gray-200 rounded-lg"
            />
            <input
              type="text"
              placeholder="Periodo"
              value={searchPeriod}
              onChange={handlePeriodChange}
              className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
            />
          </div>
          <div className="col-span-3 mr-4">
            <select
              value={filterBy}
              onChange={handleFilterChange}
              className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
            >
              <option value="">Filtrar por</option>
              <option value="carrera">Carrera</option>
              <option value="semestre">Semestre</option>
            </select>

            {filterBy === 'carrera' && (
              <select
                value={filterValue}
                onChange={handleFilterValueChange}
                className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
              >
                <option value="">Seleccionar Carrera</option>
                {carreras.map((carrera) => (
                  <option key={carrera.id} value={carrera.id}>
                    {carrera.name}
                  </option>
                ))}
              </select>
            )}

            {filterBy === 'semestre' && (
              <select
                value={filterValue}
                onChange={handleFilterValueChange}
                className="ml-2 h-10 p-2 bg-gray-200 rounded-lg"
              >
                <option value="">Seleccionar Semestre</option>
                {semestres.map((semestre) => (
                  <option key={semestre.id} value={semestre.id}>
                    {semestre.name}
                  </option>
                ))}
              </select>
            )}

            <button
              onClick={handleClearFilters}
              className="ml-6 bg-red-500 text-white px-4 py-2 rounded-lg"
            >
              Limpiar Filtros
            </button>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-4">
          <table className="w-full p-2 text-xs text-left text-gray-500 dark:text-blue-950">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-blue-950">
              <tr>
                <th scope="col" className="px-6 py-3">Nombre Completo</th>
                <th scope="col" className="px-6 py-3">No° Control</th>
                <th scope="col" className="px-6 py-3">Curp</th>
                <th scope="col" className="px-6 py-3">Teléfono</th>
                <th scope="col" className="px-6 py-3">Carrera</th>
                <th scope="col" className="px-6 py-3">Semestre</th>
                <th scope="col" className="px-6 py-3">Periodo</th>
              </tr>
            </thead>
            <tbody>
              {filteredUserData.map((user) => (
                <tr key={user.ID_student} className="bg-gray-300 border-b">
                  <td className="px-6 py-4">{`${user.name} ${user.last_f} ${user.last_m}`}</td>
                  <td className="px-6 py-4">{user.N_control}</td>
                  <td className="px-6 py-4">{user.curp}</td>
                  <td className="px-6 py-4">{user.phone}</td>
                  <td className="px-6 py-4">
                    {carreras.find(carrera => carrera.id === user.id_carrera_d)?.name || 'Ingeniero'}
                  </td>
                  <td className="px-6 py-4">
                  <input
                      readOnly
                      style={{ minWidth: '270px' }}
                      className="h-8 p-2 placeholder-slate-700 bg-slate-300 rounded-lg"
                      type="text"
                      value={
                        user.id_semestre === 1
                          ? 'Primero'
                          : user.id_semestre === 2
                          ? 'Segundo'
                          : user.id_semestre === 3
                          ? 'Tercero'
                          : user.id_semestre === 4
                          ? 'Cuarto'
                          : user.id_semestre === 5
                          ? 'Quinto'
                          : user.id_semestre === 6
                          ? 'Sexto'
                          : user.id_semestre === 7
                          ? 'Séptimo'
                          : user.id_semestre === 8
                          ? 'Octavo'
                          : user.id_semestre === 9
                          ? 'Noveno'
                          : user.id_semestre === 10
                          ? 'Décimo'
                          : user.id_semestre === 11
                          ? 'Onceavo'
                          : user.id_semestre === 12
                          ? 'Doceavo'
                          : 'No cursando'
                      }
                    />
                  </td>
                  <td className="">{user.periodo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}
