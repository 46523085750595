import { Link} from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import config from "../config/env";



export default function TeachersC(){
    const [question, setQuestion]= useState([]);

  

    const questionsMBI = async()=>{
        await axios.get(`${config.BASE_URL}/API_DualSystem/questions.php`)
        .then(questionn => {
            setQuestion(questionn.data);
            console.log(questionn)
        })
    }
    useEffect(()=>{
        questionsMBI();
    }, [])
    let id = localStorage.getItem("id")
    const handleFormSubmit = (e) => {
        e.preventDefault();
        
        // Actualiza el atributo 'action' del formulario dinámicamente
        const form = e.target;
        form.action = `${config.BASE_URL}/API_DualSystem/question_reciv.php?id=${id}`;
    
        // Envía el formulario
        form.submit();
      };
    
    return (
        <div className="w-5/6 h-auto mx-auto content-center m-8 shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]">
            <div className='relative w-full h-full p-5 flex flex-col rounded-xl mx-auto content-center shadow-[0px_1px_8px_0px_rgba(148,148,148,1)]'>

                <Link className="absolute text-red font-bold" to={'/Instrument'}>X</Link>
                <p className="text-center font-bold text-cyan-950">Cuestionario Burnout</p>
                <hr className='h-1 m-1 bg-cyan-950-200 border-0 dark:bg-gray-700'/>
                
                <form method="POST" onSubmit={handleFormSubmit}>
                {question.map((mbi, index) => (
                    <div key={`question_${index}`}>
                      
                    <p className="font-semibold mb-2"><p>{mbi.id_q}.</p>{mbi.question}</p>
                    <div className="flex flex-row text-[14px] gap-3 items-center">
                        <input className="" name={`qn_${index}`} type="radio" value='0'/>Nunca.
                        <input name={`qn_${index}`} type="radio" value='1'/>Pocas veces al año o menos.
                        <input name={`qn_${index}`} type="radio" value='2'/>Una vez al mes o menos.
                        <input name={`qn_${index}`} type="radio" value='3'/>Unas pocas veces al mes. 
                        <input name={`qn_${index}`} type="radio" value='4'/>Una vez a la semana.
                        <input name={`qn_${index}`} type="radio" value='5'/>Unas pocas veces a la semana.
                        <input name={`qn_${index}`} type="radio" value='6'/>Todos los dias.
                    </div><hr className='h-1 mt-5 mb-5 bg-cyan-950-200 border-0 dark:bg-orange-600'/>
                    </div>  ))}  <br /> <br />
                    <button type="submit">Finalizar</button>
                    
                </form>
               
                
            
                     
            </div>
        </div>
        

        
    );
}